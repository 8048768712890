import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_TEXT_COMMAND, $getSelection, $isRangeSelection } from "lexical";
import { useState, useEffect } from "react";
export function FormatToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isStrikethrough, setIsStrikethrough] = useState(false);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        setIsBold(selection.hasFormat("bold"));
        setIsItalic(selection.hasFormat("italic"));
        setIsUnderline(selection.hasFormat("underline"));
        setIsStrikethrough(selection.hasFormat("strikethrough"));
      });
    });
  }, [editor]);

  return (
    <div className="flex gap-1 border-r border-gray-200 px-2">
      <button
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "bold")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Format Bold"
        type="button"
        data-active={isBold}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M6 12h9a4 4 0 0 1 0 8H6m0-8V4h8a4 4 0 0 1 0 8H6" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "italic")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Format Italic"
        type="button"
        data-active={isItalic}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="19" y1="4" x2="10" y2="4" />
          <line x1="14" y1="20" x2="5" y2="20" />
          <line x1="15" y1="4" x2="9" y2="20" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "underline")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Format Underline"
        type="button"
        data-active={isUnderline}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M6 3v7a6 6 0 0 0 6 6 6 6 0 0 0 6-6V3" />
          <line x1="4" y1="21" x2="20" y2="21" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_TEXT_COMMAND, "strikethrough")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Format Strikethrough"
        type="button"
        data-active={isStrikethrough}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="4" y1="12" x2="20" y2="12" />
          <line x1="17.5" y1="6.5" x2="6.5" y2="17.5" />
        </svg>
      </button>
    </div>
  );
}
