import React from "react";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import RichTextLexical from "@shared-kernel/primary/shared/text-editor/rich-text/rich-text-lexical";

type FormInputs = {
  coverLetter: string;
};

interface Props {
  isStudentForm?: boolean;
}

export const CoverLetterField: React.FC<Props> = ({ isStudentForm = false }) => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext<FormInputs>();

  return (
    <FormField
      control={control}
      name="coverLetter"
      render={() => (
        <FormItem>
          <FormLabel>Lettre de motivation</FormLabel>
          {isStudentForm && (
            <FormDescription>
              Des exemples de lettre de motivation sont consultables{" "}
              <a
                href="https://docs.google.com/document/d/1M99kLEeszi94w2LNOVQbBPJjMK0-JlZGZapfqPCfysg/edit?usp=sharing"
                target="_blank"
                rel="noopener noreferrer"
                className="text-red-500"
              >
                ici
              </a>
              , vous pouvez reprendre les éléments en les personnalisant.
            </FormDescription>
          )}
          <FormControl>
            <RichTextLexical<FormInputs>
              name="coverLetter"
              control={control}
              defaultValue={defaultValues?.coverLetter}
              placeholder="Saisissez votre lettre de motivation..."
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
