import { FileProps } from "@academy-context/write/domain/constants/shared";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { FetchingState } from "src/redux/app-state";
import { v4 as uuidv4 } from "uuid";

export const getNormalizedString = (s: string) => {
  return s
    .toLowerCase()
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/’/g, "'");
};

const generateRandomLetter = (): string => String.fromCharCode(97 + Math.floor(Math.random() * 26));
export const generateRandomString = (length: number): string => {
  let res = "";
  for (let index = 0; index < length; index++) {
    res += generateRandomLetter();
  }
  return res;
};

export const urlToBloB = (url: string) => fetch(url).then(response => response.blob());

export function assertArrLength<T>(array: T[], expectedLength: number): asserts array is [T, T, T, T, T, T, T, T, T, T] {
  if (array.length !== expectedLength) {
    throw new Error("Array must have exactly 10 items");
  }
}

export function isLoadingState(state: FetchingState) {
  return ["pending"].includes(state);
}

export function isReadyState(state: FetchingState) {
  return ["success"].includes(state);
}

export const generateNewId = () => uuidv4();

export const sortAlphabetically = (a: string, b: string) => {
  return getNormalizedString(a).localeCompare(getNormalizedString(b));
};

export const getFileProps = async (file: File): Promise<FileProps> => {
  return {
    fileSize: file.size,
    type: file.type,
  };
};

export const generateInitials = (name: string, role: Nullable<ROLES>) => {
  if (!name) return "?";
  const trimmedName = name.trim();
  if (role === ROLES.PROVIDER && name[0]) return trimmedName[0];
  else
    return trimmedName
      .split(" ")
      .map(word => word[0])
      .join("");
};
