import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";

export enum DAY_BLOCK {
  MORNING = "morning",
  AFTERNOON = "afternoon",
  FULL_DAY = "full_day",
}

export const DayBlockLabelMap: Record<DAY_BLOCK, string> = {
  [DAY_BLOCK.MORNING]: "Matin",
  [DAY_BLOCK.AFTERNOON]: "Après-midi",
  [DAY_BLOCK.FULL_DAY]: "Journée",
};

export const computeHalfDaysCount = (
  trainingDays: {
    block: DAY_BLOCK;
  }[]
): number => {
  const blocks = trainingDays.map(({ block }) => block);

  let trainingDaysHalfDaysCount = 0;
  for (const block of blocks) {
    const weight = block === DAY_BLOCK.FULL_DAY ? 2 : 1;
    trainingDaysHalfDaysCount += weight;
  }
  return trainingDaysHalfDaysCount;
};

export const DayBlockOptions: ComboboxOptions[] = Object.values(DAY_BLOCK).map(o => ({
  label: DayBlockLabelMap[o],
  value: o,
}));

export interface TrainingDay {
  date: string;
  block: DAY_BLOCK;
}

interface TrainingDaysConfigBase {
  // This interval is required for the funding request but otherwise useless.
  // This means that interval can start before training days and end after training days.
  // This is why we need to store training days separately.
  interval: {
    start: string;
    end: string;
  };
  halfDays: number;
  hours: number;
}

export enum TRAINING_DAYS_CONFIG_MODE {
  AUTOMATIC = "automatic",
  MANUAL = "manual",
}

export const TRAINING_DAYS_CONFIG_MODE_MAP: Record<TRAINING_DAYS_CONFIG_MODE, string> = {
  [TRAINING_DAYS_CONFIG_MODE.AUTOMATIC]: "Automatique",
  [TRAINING_DAYS_CONFIG_MODE.MANUAL]: "Manuelle",
};

type AutomaticTrainingDaysConfig = TrainingDaysConfigBase & {
  mode: TRAINING_DAYS_CONFIG_MODE.AUTOMATIC;
};

export type ManualTrainingDaysConfig = TrainingDaysConfigBase & {
  mode: TRAINING_DAYS_CONFIG_MODE.MANUAL;
  trainingDays: TrainingDay[];
};

export type TrainingDaysConfig = AutomaticTrainingDaysConfig | ManualTrainingDaysConfig;
