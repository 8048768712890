import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { CreateConventionBody } from "@academy-context/write/domain/types/admin/convention";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  MIN_CONVENTION_HOURS,
  MAX_CONVENTION_HOURS,
} from "./form-validation/convention";
import { useConventionCreate } from "./use-convention-create";
import { FetchingState } from "@redux/app-state";
import { ADMIN_MIN_HOURLY_PRICE, MAX_HOURLY_PRICE } from "@academy-context/write/domain/constants/shared";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { PopoverCalendar } from "@shared-kernel/primary/shared/calendar/popover-calendar";
import { Equal, Loader2, Minus, Save } from "lucide-react";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { RegisteredStudent } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { ApprovedFundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import { commonAdapters } from "src/common-adapters";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  onSubmit: (body: CreateConventionBody) => void;
  processing: FetchingState;
  fundingRequests: ApprovedFundingRequestVM[];
  teachers: TeacherListElement[];
  students: RegisteredStudent[];
}

export const ConventionCreate = ({ onSubmit, processing, teachers, fundingRequests, students }: Props) => {
  const { dateProvider } = commonAdapters;
  const now = dateProvider!.now();

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    reset,
    formState: { isDirty, defaultValues: formDefaultValues, isSubmitSuccessful },
    watch,
    setValue,
  } = form;
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useConventionCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const fundingRequest = watch("fundingRequestId");

  const teacherOptions = useMemo(() => teachers.map(t => ({ value: t.teacherId, label: `${t.name} ${t.lastName}` })), [teachers]);

  const hours = watch("hours");
  const hourlyPrice = watch("hourlyPrice");
  const totalAmount = Number(hours || 0) * Number(hourlyPrice || 0);
  const teacher = watch("teacher");

  const fundingRequestOptions = useMemo(
    () =>
      [...fundingRequests]
        .sort((a, b) => new Date(b.submissionDate).getTime() - new Date(a.submissionDate).getTime())
        .map(r => {
          const student = students.find(s => s.id === r.student.id);
          const hasARunningConvention = student ? Boolean(student.convention) : false;
          return {
            value: r.id,
            label: `${new Date(r.submissionDate).toLocaleDateString("fr-FR")} | ${r.student.name} ${
              hasARunningConvention ? "| (Convention en cours)" : ""
            } | ${r.educationalAdvisor.name} | ${ORGANIZATION_NAME[r.organization].short}`,
            isDisabled: hasARunningConvention,
          };
        }),
    [fundingRequests, students]
  );

  useEffect(() => {
    const selectedFundingRequest = fundingRequests.find(r => r.id === fundingRequest);
    if (selectedFundingRequest) {
      const selectedTeacher = teacherOptions.find(s => s.value === selectedFundingRequest.educationalAdvisor.id);
      if (selectedTeacher) {
        setValue("teacher", selectedTeacher.value);
      }
    }
  }, [fundingRequest, setValue, fundingRequests, teacherOptions]);

  useEffect(() => {
    if (teacher) {
      const t = teachers.find(t => teacher === t.teacherId);
      if (t?.defaultPrice) setValue("hourlyPrice", t.defaultPrice);
    }
  }, [teachers, teacher, setValue]);

  const isBeingSubmitted = processing === "pending";

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-3 gap-3">
          <div className="space-y-4">
            <FormField
              control={form.control}
              name="fundingRequestId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Demande de financement</FormLabel>
                  <FormControl>
                    <Combobox
                      options={fundingRequestOptions}
                      value={watch("fundingRequestId")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner demande de financement..."
                      search={{
                        notFoundText: "Pas de demande de financement trouvée.",
                        commandInputPlaceHolder: "Chercher demande de financement...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="teacher"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Professeur</FormLabel>
                  <FormControl>
                    <Combobox
                      options={teacherOptions}
                      value={watch("teacher")}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner professeur..."
                      search={{
                        notFoundText: "Pas de professeur trouvé.",
                        commandInputPlaceHolder: "Chercher professeur...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="date"
              render={({ field }) => {
                return (
                  <FormItem className="flex flex-col gap-2">
                    <FormLabel>Date</FormLabel>
                    <PopoverCalendar value={field.value} onChange={value => field.onChange(value)} now={now} />
                    <FormMessage />
                  </FormItem>
                );
              }}
            />
          </div>
          <div className="col-span-2">
            <div className="flex items-end gap-2">
              <FormField
                control={form.control}
                name="hours"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Heures</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={MIN_CONVENTION_HOURS}
                        max={MAX_CONVENTION_HOURS}
                        {...field}
                        value={watch("hours")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Minus className="mb-2" />
              <FormField
                control={form.control}
                name="hourlyPrice"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Tarif horaire</FormLabel>
                    <FormControl>
                      <Input
                        type="number"
                        step="1"
                        min={ADMIN_MIN_HOURLY_PRICE}
                        max={MAX_HOURLY_PRICE}
                        {...field}
                        value={watch("hourlyPrice")}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Equal className="mb-2" />
              <FormItem>
                <FormLabel>Prix total</FormLabel>
                <FormControl>
                  <Input type="number" disabled value={totalAmount} />
                </FormControl>
                <FormMessage />
              </FormItem>
            </div>
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Commentaire</FormLabel>
                  <FormControl>
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={form.control}
                      defaultValue={formDefaultValues?.[field.name]}
                      isSubmitSuccessful={isSubmitSuccessful}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-3 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || isBeingSubmitted}>
              {isBeingSubmitted ? <Loader2 className="mr-2 animate-spin" size={24} /> : <Save className="mr-2" size={24} />}
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
