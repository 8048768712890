import { useEffect, useMemo, useRef, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { FormInputs, schema, defaultValues, formatFormDataToBodyDataCreateFundingRequest } from "./form-validation/funding-request";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState, FetchingState } from "@redux/app-state";
import { resetUploadBio, uploadBio } from "../../../write/application/use-cases/student/bio-upload/upload-bio";
import { resetUploadResume, uploadResume } from "../../../write/application/use-cases/student/resume-upload/upload-resume";
import { BIO_AND_RESUME_FILETYPE, isValidFile } from "@academy-context/write/domain/constants/shared";
import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Send, ArrowUpRightFromCircle, PlusCircle, CircleX } from "lucide-react";
import { UploadButton } from "@shared-kernel/primary/shared/upload-button/upload-button";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import {
  resetStudentRetrieveProviderBatches,
  studentRetrieveProviderBatches,
} from "@academy-context/read/application/use-cases/student/provider-batch-list-retrieval/retrieve-provider-batch-list";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { getFileProps } from "@utils/utils";
import { AFDAS_STUDENT_STATUS_OPTIONS, FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { AFDAS_STUDENT_STATUS } from "@academy-context/shared/domain/types/enums/funders";
import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { TeacherVM } from "@user-management-context/read/domain/types/student/teacher";
import { ProviderVM } from "@user-management-context/read/domain/types/student/provider";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { StudentProviderEnrollment } from "@academy-context/read/domain/types/student/provider-enrollment";
import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { StudentInternalTraining } from "@academy-context/read/domain/types/student/internal-training";
import { StudentProviderBatchItem } from "@academy-context/read/domain/types/student/provider-batch";
import { InternalTrainingOrderedQuestion } from "@academy-context/read/domain/types/admin/internal-training";
import { ProviderTrainingOrderedQuestion } from "@academy-context/read/domain/types/shared/provider-training";
import { Checkbox } from "@components/ui/checkbox";
import { Student } from "@user-management-context/read/domain/types/student/student";
import { FUNDER_TUTORIALS } from "@academy-context/primary/student/funding-request/funder-tutorials";
import { CoverLetterField } from "@academy-context/primary/shared/funding-request-form/cover-letter-field";
import { PersonalizedEducationalProjectField } from "@academy-context/primary/student/funding-request/personalized-educational-project-field";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  onSubmit: (body: CreateFundingRequestBody) => void;
  internalTrainingsOptions: ComboboxOptions[];
  rawInternalTrainings: StudentInternalTraining[];
  teachers: TeacherVM[];
  providers: ProviderVM[];
  providerBatchesOptions: ComboboxOptions[];
  rawProviderBatches: StudentProviderBatchItem[];
  processing: FetchingState;
  studentProviderEnrollment: Nullable<StudentProviderEnrollment>;
  student: Student;
}

export const FundingRequestForm = ({
  onSubmit,
  internalTrainingsOptions,
  rawInternalTrainings,
  teachers,
  providers,
  providerBatchesOptions,
  rawProviderBatches,
  processing,
  studentProviderEnrollment,
  student,
}: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { bioUrl, processing: bioProcessing } = useAppSelector((state: AppState) => state.bioUpload);
  const { resumeUrl, processing: resumeProcessing } = useAppSelector((state: AppState) => state.resumeUpload);
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);
  const [needsAccommodation, setNeedsAccommodation] = useState(false);
  const [isUndefinedTeacher, setIsUndefinedTeacher] = useState(false);

  const hasEligibleProviderEnrollment = Boolean(studentProviderEnrollment);
  const skipUseEffect = useRef(false);

  const {
    formState: { isDirty, defaultValues: formDefaultValues, isSubmitSuccessful },
    setValue,
    control,
    resetField,
    clearErrors,
    setError,
    watch,
    reset,
  } = form;

  const { append: appendSecondaryTeachers, remove: removeSecondaryTeachers } = useFieldArray<FormInputs>({
    control,
    name: "secondaryTeachers",
  });

  const { append: appendQuestions } = useFieldArray<FormInputs>({
    control,
    name: "questions",
  });

  const educationalAdvisorId = watch("educationalAdvisorId");
  const educationalAdvisorType = watch("educationalAdvisorType");
  const isProvider = educationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;
  const funderType = watch("funder.type");
  const internalTrainingId = watch("internalTrainingId");
  const providerBatchId = watch("providerBatchId");

  useEffect(() => {
    // All this complexity is due to the fact that we don't want this useEffect to be triggered after form initialization
    // Otherwise, it resets all the values set in the form initialization
    if (skipUseEffect.current) {
      skipUseEffect.current = false;
      return;
    }

    setValue("educationalAdvisorId", "", { shouldDirty: false });
    setValue("internalTrainingId", "", { shouldDirty: false });
    setValue("providerBatchId", "", { shouldDirty: false });
  }, [educationalAdvisorType, setValue, isUndefinedTeacher]);

  useEffect(() => {
    // Reset the provider batches when the educational advisor id changes otherwise if the student switches between teacher and provider, the provider batches are not reset
    dispatch(resetStudentRetrieveProviderBatches());
    if (isProvider && educationalAdvisorId) dispatch(studentRetrieveProviderBatches(educationalAdvisorId));
    // Disabled otherwise this is triggered on the switch between teacher and provider which executes the dispatch event though it is the teacher's educational advisor id specified (results in a 404)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, educationalAdvisorId]);

  useEffect(() => {
    if (isProvider) resetField("secondaryTeachers");
  }, [setValue, educationalAdvisorType, isProvider, resetField]);

  useEffect(() => {
    if (bioUrl) {
      setValue("bioUrl", bioUrl, { shouldDirty: true });
    }
  }, [bioUrl, setValue]);

  useEffect(() => {
    if (resumeUrl) {
      setValue("resumeUrl", resumeUrl, { shouldDirty: true });
    }
  }, [resumeUrl, setValue]);

  useEffect(() => {
    resetField("funder.payload.id");
    resetField("funder.payload.password");
    resetField("funder.payload.studentStatus");
    resetField("funder.payload.postCode");
  }, [funderType, resetField]);

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  useEffect(() => {
    const questions: FormInputs["questions"] = [];
    const trainings: { id: string; questions: (InternalTrainingOrderedQuestion | ProviderTrainingOrderedQuestion)[] }[] = isProvider
      ? rawProviderBatches
      : rawInternalTrainings;
    const trainingId = isProvider ? providerBatchId : internalTrainingId;
    const selectedTraining = trainings.find(training => training.id === trainingId);
    if (selectedTraining) {
      for (const q of selectedTraining.questions) {
        questions.push({
          question: q.text,
          order: q.order,
          answer: "",
        });
      }
    }
    resetField("questions");
    appendQuestions(questions);
  }, [appendQuestions, internalTrainingId, isProvider, providerBatchId, rawInternalTrainings, rawProviderBatches, resetField]);

  useEffect(() => {
    if (!needsAccommodation) setValue("specificAccommodation", "", { shouldDirty: false });
  }, [needsAccommodation, setValue]);

  // This useEffect has to be below the previous ones to avoid the reset of the educational advisor id
  useEffect(() => {
    // If the student is invited to a provider batch, they are required to use it
    if (hasEligibleProviderEnrollment && studentProviderEnrollment) {
      // Need to specify defaultValues otherwise all values are reset to undefined
      reset({
        ...defaultValues,
        educationalAdvisorId: studentProviderEnrollment.providerId,
        educationalAdvisorType: EDUCATIONAL_ADVISOR.PROVIDER,
        providerBatchId: studentProviderEnrollment.batchId,
      });
      skipUseEffect.current = true;
    }
  }, [hasEligibleProviderEnrollment, studentProviderEnrollment, reset]);

  const handleOnSubmit = async (formBody: FormInputs) => {
    setShouldBlockNavigation(false);
    const body = formatFormDataToBodyDataCreateFundingRequest(formBody);
    onSubmit(body);
  };

  const handleResumeUrlChange = (resume: File) => {
    clearErrors("resumeUrl");
    dispatch(uploadResume({ resume }));
  };

  const handleResumeUrlError = () => {
    setError("resumeUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const handleResumeUrlDelete = () => {
    clearErrors("resumeUrl");
    setValue("resumeUrl", "", { shouldDirty: true });
    dispatch(resetUploadResume());
  };

  const handleBioUrlChange = (bio: File) => {
    clearErrors("bioUrl");
    dispatch(uploadBio({ bio }));
  };

  const handleBioUrlError = () => {
    setError("bioUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const handleBioUrlDelete = () => {
    clearErrors("bioUrl");
    setValue("bioUrl", "", { shouldDirty: true });
    dispatch(resetUploadBio());
  };

  const teacherOptions: ComboboxOptions[] = teachers.map(t => ({ label: `${t.name} ${t.lastName}`, value: t.id }));
  const providerOptions: ComboboxOptions[] = useMemo(() => {
    const allProviderOptions = providers.map(p => ({ label: p.name, value: p.id }));
    return allProviderOptions;
  }, [providers]);
  const funderOptions: ComboboxOptions[] = Object.values(FUNDER)
    .filter(funder => {
      if (student.canRequestANonAfdasFunding) return true;
      return funder === FUNDER.AFDAS;
    })
    .map(f => ({ label: FUNDER_LABELS[f], value: f }));
  const afdasStudentStatusOptions: ComboboxOptions[] = Object.values(AFDAS_STUDENT_STATUS).map(status => ({
    label: AFDAS_STUDENT_STATUS_OPTIONS[status],
    value: status,
  }));

  const bioFromForm = watch("bioUrl");
  const bio = bioUrl || bioFromForm;
  const resumeFromForm = watch("resumeUrl");
  const resume = resumeUrl || resumeFromForm;

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
          <FormField
            control={form.control}
            name="funder.type"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Type de financeur</FormLabel>
                <FormControl>
                  <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                    {funderOptions.map(option => (
                      <FormItem className="flex items-center space-x-3 space-y-0" key={option.value}>
                        <FormControl>
                          <RadioGroupItem value={option.value} />
                        </FormControl>
                        <FormLabel className="font-normal">{option.label}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          {FUNDER_TUTORIALS[funderType]}
          {funderType === FUNDER.AFDAS && (
            <>
              <FormField
                control={form.control}
                name="funder.payload.id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Votre identifiant AFDAS</FormLabel>
                    <FormControl>
                      <Input placeholder={`Adresse mail Ex: marc@gmail.com`} {...field} type="email" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="funder.payload.password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Votre mot de passe AFDAS</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="funder.payload.studentStatus"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Statut AFDAS</FormLabel>
                    <FormControl>
                      <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                        {afdasStudentStatusOptions.map(option => (
                          <FormItem className="flex items-center space-x-3 space-y-0" key={option.value}>
                            <FormControl>
                              <RadioGroupItem value={option.value} />
                            </FormControl>
                            <FormLabel className="font-normal">{option.label}</FormLabel>
                          </FormItem>
                        ))}
                      </RadioGroup>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          {funderType === FUNDER.FRANCE_TRAVAIL && (
            <>
              <FormField
                control={form.control}
                name="funder.payload.id"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Votre identifiant France Travail</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="funder.payload.postCode"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Code postal déclaré à France Travail</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </>
          )}
          <FormField
            control={form.control}
            name="educationalAdvisorType"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormLabel>Professeur / Prestataire principal</FormLabel>
                  <FormDescription>
                    Professeur / Prestataire auquel vous allouerez la majeure partie de ce financement (c’est-à-dire environ 30% du
                    financement)
                  </FormDescription>
                  <RadioGroup
                    onValueChange={value => field.onChange(value)}
                    value={field.value}
                    className="flex flex-col space-y-1"
                    disabled={hasEligibleProviderEnrollment || isUndefinedTeacher}
                  >
                    {Object.values(EDUCATIONAL_ADVISOR).map(v => (
                      <FormItem className="flex items-center space-x-3 space-y-0" key={v}>
                        <FormControl>
                          <RadioGroupItem value={v} />
                        </FormControl>
                        <FormLabel className="font-normal">{EDUCATIONAL_ADVISOR_MAP[v]}</FormLabel>
                      </FormItem>
                    ))}
                  </RadioGroup>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="educationalAdvisorId"
            render={({ field }) => {
              return (
                <FormItem>
                  <FormControl>
                    {isProvider ? (
                      <Combobox
                        options={providerOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner prestataire..."
                        search={{
                          notFoundText: "Pas de prestataire trouvé.",
                          commandInputPlaceHolder: "Chercher prestataire...",
                        }}
                        disabled={hasEligibleProviderEnrollment}
                      />
                    ) : (
                      <>
                        <Combobox
                          options={teacherOptions}
                          value={field.value}
                          onChange={value => field.onChange(value)}
                          placeholder="Selectionner professeur..."
                          search={{
                            notFoundText: "Pas de professeur trouvé.",
                            commandInputPlaceHolder: "Chercher professeur...",
                          }}
                          disabled={isUndefinedTeacher}
                        />
                        <div className="flex items-center space-x-3 space-y-0">
                          <Checkbox
                            checked={isUndefinedTeacher}
                            onCheckedChange={checked => setIsUndefinedTeacher(Boolean(checked))}
                            id="undefined-teacher"
                            disabled={hasEligibleProviderEnrollment}
                          />
                          <label htmlFor="undefined-teacher">
                            <span className="text-sm">Je ne suis pas certain de mon professeur principal</span>
                            <FormDescription>
                              Vous avez 4 semaines pour nous confirmer votre choix. Au dela de cette date, sans réponse de votre part, votre
                              compte sera mis en pause.
                            </FormDescription>
                          </label>
                        </div>
                      </>
                    )}
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          {isProvider ? (
            <>
              <FormField
                control={form.control}
                name="providerBatchId"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Intitulé de la session</FormLabel>
                    <FormControl>
                      <Combobox
                        options={providerBatchesOptions}
                        value={field.value}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner session..."
                        search={{
                          notFoundText: "Pas de session trouvée.",
                          commandInputPlaceHolder: "Chercher session...",
                        }}
                        disabled={hasEligibleProviderEnrollment}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <div className="text-sm font-bold text-red-500">
                Il est nécessaire que vous ayez pris contact avec le prestataire pour confirmer votre participation à l’une de leurs
                sessions. <br />
                Cette demande sert uniquement à obtenir un financement et ne garantit pas votre place si vous n’avez pas préalablement
                convenu d'un accord avec le prestataire.
              </div>
            </>
          ) : (
            <FormField
              control={form.control}
              name="internalTrainingId"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Intitulé de la formation</FormLabel>
                  <FormDescription>
                    Choisissez la thématique de formation qui correspond le mieux à vos objectifs. Vous aurez l'occasion de préciser
                    davantage votre projet pédagogique. Cette thématique sert de cadre général.
                  </FormDescription>
                  <FormControl>
                    <Combobox
                      options={internalTrainingsOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner formation..."
                      search={{
                        notFoundText: "Pas de formation trouvé.",
                        commandInputPlaceHolder: "Chercher formation...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          )}
          <FormItem>
            <FormLabel>Télécharger Bio et/ou CV</FormLabel>
            <FormDescription>Dans l’idéal, fournissez votre bio ET votre CV</FormDescription>
            <FormField
              control={form.control}
              name="bioUrl"
              render={() => (
                <FormItem>
                  <div className="flex items-center">
                    <UploadButton
                      processing={bioProcessing}
                      label="Télécharger ma bio (pdf)"
                      onChange={handleBioUrlChange}
                      onError={handleBioUrlError}
                      types={BIO_AND_RESUME_FILETYPE}
                      value={bio}
                      validatorFunc={isValidFile}
                      onDelete={handleBioUrlDelete}
                      getFileProps={getFileProps}
                    />
                    {bio && (
                      <a href={bio} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                        <span className="text-sm font-normal">Aperçu</span>
                        <ArrowUpRightFromCircle size={18} />
                      </a>
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="resumeUrl"
              render={() => (
                <FormItem className="mt-2">
                  <div className="flex items-center">
                    <UploadButton
                      processing={resumeProcessing}
                      label="Télécharger mon CV (pdf)"
                      onChange={handleResumeUrlChange}
                      onError={handleResumeUrlError}
                      types={BIO_AND_RESUME_FILETYPE}
                      value={resume}
                      validatorFunc={isValidFile}
                      onDelete={handleResumeUrlDelete}
                      getFileProps={getFileProps}
                    />
                    {resume && (
                      <a href={resume} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                        <span className="text-sm font-normal">Aperçu</span>
                        <ArrowUpRightFromCircle size={18} />
                      </a>
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
          </FormItem>
          <CoverLetterField isStudentForm />
          <PersonalizedEducationalProjectField />
          {!isProvider && (
            <FormField
              control={control}
              name="secondaryTeachers"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Professeurs complémentaires</FormLabel>
                    <FormDescription>
                      Constituez votre équipe pédagogique, si vous avez l’intention de travailler avec des professeurs complémentaires.
                    </FormDescription>
                    <FormControl className="w-[100px]">
                      <>
                        {field.value.map(({ teacherId }, index) => {
                          return (
                            <FormField
                              key={teacherId + index}
                              control={control}
                              name={`secondaryTeachers.${index}.teacherId`}
                              render={({ field: itemField }) => (
                                <FormItem>
                                  <FormControl>
                                    <div className="relative">
                                      <Combobox
                                        options={teacherOptions}
                                        value={itemField.value}
                                        onChange={value => itemField.onChange(value)}
                                        placeholder="Selectionner professeur..."
                                        search={{
                                          notFoundText: "Pas de professeur trouvé.",
                                          commandInputPlaceHolder: "Chercher professeur...",
                                        }}
                                      />
                                      <button
                                        type="button"
                                        className="absolute right-[18px] top-1/2 -translate-y-1/2 cursor-pointer bg-white text-red-500 opacity-100 hover:text-red-700"
                                        onClick={() => removeSecondaryTeachers(index)}
                                      >
                                        <CircleX />
                                      </button>
                                    </div>
                                  </FormControl>
                                  <FormMessage />
                                </FormItem>
                              )}
                            />
                          );
                        })}
                      </>
                    </FormControl>
                    <FormMessage />
                    <Button
                      onClick={() => appendSecondaryTeachers({ teacherId: "" })}
                      className="mt-2"
                      type="button"
                      variant="outline"
                      disabled={isUndefinedTeacher}
                    >
                      <PlusCircle className="mr-3" />
                      Ajouter un professeur complémentaire
                    </Button>
                  </FormItem>
                );
              }}
            />
          )}
          <FormField
            control={control}
            name="questions"
            render={({ field }) => {
              if (field.value.length < 1) return <></>;
              return (
                <FormItem>
                  <FormLabel>Questionnaire de positionnement</FormLabel>
                  <FormControl className="w-[100px]">
                    <>
                      {field.value.map(({ order }, index) => (
                        <FormField
                          key={order}
                          control={control}
                          name={`questions.${index}.answer`}
                          render={() => {
                            const value = field.value[index];
                            const question = value?.question ?? "";
                            return (
                              <FormItem>
                                <FormLabel className="font-normal">{question}</FormLabel>
                                <FormControl>
                                  <PlainTextLexical<FormInputs>
                                    name={`questions.${index}.answer`}
                                    control={form.control}
                                    defaultValue={formDefaultValues?.questions?.[index]?.answer}
                                    className="h-[100px]"
                                    isSubmitSuccessful={isSubmitSuccessful}
                                  />
                                </FormControl>
                                <FormMessage />
                              </FormItem>
                            );
                          }}
                        />
                      ))}
                    </>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="specificAccommodation"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Aménagements spécifiques</FormLabel>
                <div className="flex items-center space-x-3 space-y-0">
                  <Checkbox
                    checked={needsAccommodation}
                    onCheckedChange={checked => setNeedsAccommodation(Boolean(checked))}
                    id="specificAccommodation"
                  />
                  <label htmlFor="specificAccommodation">
                    <FormDescription>
                      J’ai besoin d'un aménagement particulier en raison d'un handicap, d'une maladie, ou de toute autre situation pouvant
                      compliquer l'accès à la formation. Ex : Adaptation du matériel, des salles ou des horaires en conséquence.
                    </FormDescription>
                  </label>
                </div>
                <FormControl>
                  {needsAccommodation && (
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={form.control}
                      defaultValue={field.value}
                      placeholder={needsAccommodation ? "Saisir votre aménagement spécifique" : undefined}
                      isSubmitSuccessful={isSubmitSuccessful}
                    />
                  )}
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="monthlyLessonsCount"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Combien de cours par mois pensez-vous prendre ?</FormLabel>
                <FormControl className="w-[150px]">
                  <Input {...field} type="number" min="1" />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <div className="flex justify-end">
            <Button type="submit" disabled={!isDirty || processing === "pending"}>
              <Send className="mr-2 size-4" />
              Envoyer ma demande pour validation
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
