import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { FORMAT_ELEMENT_COMMAND, $getSelection, $isRangeSelection } from "lexical";
import { useState, useEffect } from "react";
type AlignmentType = "left" | "center" | "right" | "justify";
const alignmentMap: Record<number, AlignmentType> = {
  0: "left",
  1: "left",
  2: "center",
  3: "right",
  4: "justify",
};

export function AlignmentToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [alignment, setAlignment] = useState<AlignmentType>("left");

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        const node = selection.getNodes()[0];
        if (!node) return;

        const element = node.getParent();
        if (!element) return;

        const elementFormat = element.getFormat() as 1 | 2 | 3 | 4 | 0;

        const newAlignment = alignmentMap[elementFormat];
        if (newAlignment && newAlignment !== alignment) {
          setAlignment(newAlignment);
        }
      });
    });
  }, [editor, alignment]);

  return (
    <div className="flex gap-1 px-2">
      <button
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "left")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Align Left"
        type="button"
        data-active={alignment === "left"}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="12" x2="15" y2="12" />
          <line x1="3" y1="18" x2="18" y2="18" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "center")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Align Center"
        type="button"
        data-active={alignment === "center"}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="6" y1="12" x2="18" y2="12" />
          <line x1="4" y1="18" x2="20" y2="18" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "right")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Align Right"
        type="button"
        data-active={alignment === "right"}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="9" y1="12" x2="21" y2="12" />
          <line x1="6" y1="18" x2="21" y2="18" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(FORMAT_ELEMENT_COMMAND, "justify")}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Justify"
        type="button"
        data-active={alignment === "justify"}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <line x1="3" y1="6" x2="21" y2="6" />
          <line x1="3" y1="12" x2="21" y2="12" />
          <line x1="3" y1="18" x2="21" y2="18" />
        </svg>
      </button>
    </div>
  );
}
