import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { LexicalErrorBoundary } from "@lexical/react/LexicalErrorBoundary";
import { EditorRefPlugin } from "@lexical/react/LexicalEditorRefPlugin";
import { ClearEditorPlugin } from "@lexical/react/LexicalClearEditorPlugin";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import { $getRoot, $insertNodes, $setSelection, CLEAR_EDITOR_COMMAND, EditorState, LexicalEditor } from "lexical";
import { $generateHtmlFromNodes, $generateNodesFromDOM } from "@lexical/html";
import { cn } from "@components/utils/utils";
import { MutableRefObject, useEffect, useRef } from "react";

interface PlainTextProps<T extends FieldValues> {
  name: Path<T>;
  control: Control<T>;
  defaultValue?: string;
  placeholder?: string;
  onTextChange?: (text: string) => void;
  className?: string;
  editorRef?: MutableRefObject<LexicalEditor | null>;
  isSubmitSuccessful: boolean;
  disabled?: boolean;
}

const theme = {
  paragraph: "plain-text-paragraph",
  root: "outline-none",
};

function PlainTextLexical<T extends FieldValues>({
  name,
  control,
  defaultValue,
  placeholder,
  onTextChange,
  className,
  isSubmitSuccessful,
  disabled = false,
}: PlainTextProps<T>): JSX.Element {
  const editorRef = useRef<LexicalEditor>(null);
  const initialConfig: InitialConfigType = {
    namespace: "PlainTextEditor",
    theme,
    onError: (error: Error) => {
      console.error(error);
    },
    editorState: (editor: LexicalEditor) => {
      if (defaultValue) {
        editor.update(() => {
          const parser = new DOMParser();
          const dom = parser.parseFromString(defaultValue, "text/html");
          const nodes = $generateNodesFromDOM(editor, dom);
          $getRoot().select();
          $insertNodes(nodes);
          $setSelection(null);
        });
      }
    },
  };

  useEffect(() => {
    if (isSubmitSuccessful && editorRef.current) {
      editorRef.current.dispatchCommand(CLEAR_EDITOR_COMMAND, undefined);
    }
  }, [isSubmitSuccessful]);

  useEffect(() => {
    if (disabled && editorRef.current) {
      editorRef.current.setEditable(disabled);
    }
  }, [disabled]);

  const handleEditorChange = (editorState: EditorState, editor: LexicalEditor, onChange: (value: string) => void) => {
    editorState.read(() => {
      const root = $getRoot();
      const htmlString = $generateHtmlFromNodes(editor, null);

      const textContent = root.getTextContent().trim();
      const isEmpty = textContent.length === 0 || htmlString === '<p class="rich-text-paragraph"><br></p>';
      if (isEmpty) {
        onChange("");
        onTextChange?.("");
      } else {
        onChange(htmlString);
        onTextChange?.(htmlString);
      }
    });
  };

  return (
    <Controller<T>
      name={name}
      control={control}
      render={({ field: { onChange }, fieldState: { error } }) => (
        <LexicalComposer initialConfig={initialConfig}>
          <div className={cn("relative rounded-md border border-gray-300 bg-white", error && "border-red-500")}>
            <div className={cn("h-[150px] overflow-hidden", className)}>
              <PlainTextPlugin
                contentEditable={
                  <div className="relative h-full">
                    <ContentEditable className={cn("absolute inset-0 overflow-y-auto px-4 py-3 text-sm leading-5")} />
                  </div>
                }
                placeholder={
                  <div className={cn("absolute top-3 left-4 pointer-events-none select-none leading-5 text-gray-400")}>{placeholder}</div>
                }
                ErrorBoundary={LexicalErrorBoundary}
              />
            </div>
            <OnChangePlugin
              onChange={(editorState, editor) => {
                handleEditorChange(editorState, editor, onChange);
              }}
            />
            <HistoryPlugin />
            <EditorRefPlugin editorRef={editorRef} />
            <ClearEditorPlugin />
          </div>
        </LexicalComposer>
      )}
    />
  );
}

export default PlainTextLexical;
