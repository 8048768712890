import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  MIN_BALANCE_ADJUSTMENT_PRICE,
  MAX_BALANCE_ADJUSTMENT_PRICE,
} from "./form-validation/balance-adjustment";
import { useBalanceAdjustmentCreate } from "./use-balance-adjustment-create";
import { CreatableSelect } from "@shared-kernel/primary/shared/creatable-select/creatable-select";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { formatNumberToLocale } from "../../../../utils/formatting";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { selectRegisteredStudents } from "@user-management-context/read/application/use-cases/admin/students-retrieval/selectors/registered-students-selectors";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import { CreateBalanceAdjustmentBody } from "@shared-kernel/application/ports/admin/balance-adjustment-repository";
import { AppState, FetchingState } from "@redux/app-state";
import { Label } from "@components/ui/label";
import { cn } from "@components/utils/utils";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useEffect, useMemo } from "react";
import { resetUploadDocument, uploadDocument } from "@academy-context/write/application/use-cases/admin/document-upload/upload-document";
import { UploadButton } from "@shared-kernel/primary/shared/upload-button/upload-button";
import { BIO_AND_RESUME_FILETYPE, isValidFile } from "@academy-context/write/domain/constants/shared";
import { getFileProps } from "@utils/utils";
import { ArrowUpRightFromCircle } from "lucide-react";
import { OrganisationOptions, ORGANIZATION } from "@shared-kernel/domain/organisation";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  onSubmit: (body: CreateBalanceAdjustmentBody) => void;
  students: ReturnType<typeof selectRegisteredStudents>;
  processing: FetchingState;
}

const typeOptions = [
  {
    label: "Masterclass",
    value: "Masterclass",
  },
  {
    label: "Frais",
    value: "Frais",
  },
  {
    label: "Re-crédit cours doublon",
    value: "Re-crédit cours doublon",
  },
  {
    label: "Re-crédit cours offert",
    value: "Re-crédit cours offert",
  },
  {
    label: "Re-crédit Stage",
    value: "Re-crédit Stage",
  },
];

export const BalanceAdjustmentCreate = ({ onSubmit, students, processing }: Props) => {
  const dispatch = useAppDispatch();
  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });
  const { url: documentUrl, processing: documentUploadProcessing } = useAppSelector((state: AppState) => state.adminDocumentUpload);

  const {
    control,
    reset,
    formState: { isDirty, defaultValues: formDefaultValues, isSubmitSuccessful },
    watch,
    setValue,
    clearErrors,
    setError,
  } = form;

  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(isDirty);

  useEffect(() => {
    if (documentUrl) {
      setValue("documentUrl", documentUrl, { shouldDirty: true });
    }
  }, [documentUrl, setValue]);

  useBalanceAdjustmentCreate({ reset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const handleDocumentUrlChange = (document: File) => {
    clearErrors("documentUrl");
    dispatch(uploadDocument({ document }));
  };

  const handleDocumentUrlError = () => {
    setError("documentUrl", { type: "manual", message: "Veuillez télécharger un fichier PDF de 10 Mo max" });
  };

  const handleDocumentUrlDelete = () => {
    clearErrors("documentUrl");
    setValue("documentUrl", "", { shouldDirty: true });
    dispatch(resetUploadDocument());
  };

  const studentOptions = students.map(s => ({
    value: s.id,
    label: `${s.name} ${s.lastName} | ${formatNumberToLocale(s.balance.global, 2)}€`,
  }));

  const student = watch("student");
  const currentBalance = useMemo(() => {
    return (
      students.find(s => s.id === student)?.balance || {
        global: 0,
        byOrganization: {
          [ORGANIZATION.OPERA_OFF]: 0,
          [ORGANIZATION.LES_ATELIERS_OO]: 0,
        },
      }
    );
  }, [students, student]);
  const amount = Number(watch("amount")) || 0;
  const organization = watch("organization");

  const newGlobalBalance = currentBalance.global + amount;

  const ooBalance = currentBalance.byOrganization[ORGANIZATION.OPERA_OFF];
  const adjustedOOBalance = organization === ORGANIZATION.OPERA_OFF ? amount : 0;
  const newOOBalance = ooBalance + adjustedOOBalance;

  const laooBalance = currentBalance.byOrganization[ORGANIZATION.LES_ATELIERS_OO];
  const adjustedLAOOBalance = organization === ORGANIZATION.LES_ATELIERS_OO ? amount : 0;
  const newLAOOBalance = laooBalance + adjustedLAOOBalance;

  const documentFromForm = watch("documentUrl");
  const document = documentUrl || documentFromForm;

  return (
    <>
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-6 gap-3">
          <div className="col-span-2 space-y-4">
            <FormField
              control={form.control}
              name="student"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Élève</FormLabel>
                  <FormControl>
                    <Combobox
                      options={studentOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner élève..."
                      search={{
                        notFoundText: "Pas d'élève trouvé.",
                        commandInputPlaceHolder: "Chercher élève...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="amount"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Montant</FormLabel>
                  <FormControl>
                    <Input
                      {...field}
                      type="number"
                      step="0.01"
                      min={MIN_BALANCE_ADJUSTMENT_PRICE}
                      max={MAX_BALANCE_ADJUSTMENT_PRICE}
                      value={field.value}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={control}
              name="label"
              render={() => (
                <FormItem>
                  <FormLabel>Type</FormLabel>
                  <CreatableSelect formName={"label"} control={control} options={typeOptions} />
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="documentUrl"
              render={() => (
                <FormItem>
                  <div className="flex items-center">
                    <UploadButton
                      processing={documentUploadProcessing}
                      label="Télécharger un document (pdf)"
                      onChange={handleDocumentUrlChange}
                      onError={handleDocumentUrlError}
                      types={BIO_AND_RESUME_FILETYPE}
                      value={document}
                      validatorFunc={isValidFile}
                      onDelete={handleDocumentUrlDelete}
                      getFileProps={getFileProps}
                    />
                    {document && (
                      <a href={document} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                        <span className="text-sm font-normal">Aperçu</span>
                        <ArrowUpRightFromCircle size={18} />
                      </a>
                    )}
                  </div>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="organization"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Société</FormLabel>
                  <FormControl>
                    <Combobox
                      options={OrganisationOptions}
                      value={field.value}
                      onChange={value => field.onChange(value)}
                      placeholder="Selectionner la société ..."
                      search={{
                        notFoundText: "Pas de société trouvée.",
                        commandInputPlaceHolder: "Chercher société...",
                      }}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-2 space-y-4">
            <div className="space-y-4">
              <div className="space-y-2">
                <Label htmlFor="current-balance">Solde global actuel</Label>
                <Input id="current-balance" value={`${formatNumberToLocale(currentBalance?.global || 0)}€`} disabled />
              </div>
              <div className="space-y-2">
                <Label htmlFor="new-balance">Nouveau solde global</Label>
                <Input
                  id="new-balance"
                  value={`${formatNumberToLocale(newGlobalBalance || 0)}€`}
                  disabled
                  className={cn("disabled:opacity-90", amount === 0 ? "" : amount < 0 ? "text-red-500" : "text-green-500")}
                />
              </div>
            </div>
            <div className="flex space-x-2">
              <div className="col-span-1 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="current-balance">Solde OO actuel</Label>
                  <Input id="current-balance" value={`${formatNumberToLocale(ooBalance)}€`} disabled />
                </div>
                <div className="space-y-2">
                  <Label htmlFor="new-balance">Nouveau solde OO</Label>
                  <Input
                    id="new-balance"
                    value={`${formatNumberToLocale(newOOBalance || 0)}€`}
                    disabled
                    className={cn(
                      "disabled:opacity-90",
                      adjustedOOBalance === 0 ? "" : adjustedOOBalance < 0 ? "text-red-500" : "text-green-500"
                    )}
                  />
                </div>
              </div>
              <div className="col-span-1 space-y-4">
                <div className="space-y-2">
                  <Label htmlFor="current-balance">Solde LAOO actuel</Label>
                  <Input id="current-balance" value={`${formatNumberToLocale(laooBalance)}€`} disabled />
                </div>

                <div className="space-y-2">
                  <Label htmlFor="new-balance">Nouveau solde LAOO</Label>
                  <Input
                    id="new-balance"
                    value={`${formatNumberToLocale(newLAOOBalance || 0)}€`}
                    disabled
                    className={cn(
                      "disabled:opacity-90",
                      adjustedLAOOBalance === 0 ? "" : adjustedLAOOBalance < 0 ? "text-red-500" : "text-green-500"
                    )}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="col-span-2 space-y-4">
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={form.control}
                      defaultValue={formDefaultValues?.[field.name]}
                      isSubmitSuccessful={isSubmitSuccessful}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-6 mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || processing === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </>
  );
};
