import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Button } from "@components/ui/button";
import { Switch } from "@components/ui/switch";
import { Label } from "@components/ui/label";
import { ProviderBatchListSkeleton } from "@academy-context/primary/shared/provider-batch/provider-batch-list.skeleton";
import { ProviderBatch } from "@academy-context/primary/provider/provider-batches/provider-batch.components";
import { CreateBatchModal } from "@academy-context/primary/shared/provider-batch/create-batch-modal";
import {
  CreateProviderBatchBody,
  ProviderStudentEnrollmentToBatchBody,
} from "@user-management-context/shared/application/ports/provider-repository";
import { createProviderBatch } from "@academy-context/write/application/use-cases/shared/provider-batch-creation/create-provider-batch";
import { adminRetrieveProviderTrainings } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/retrieve-provider-trainings";
import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { adminRetrieveProviders } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/retrieve-providers";
import { selectProvidersRetrievalForList } from "@user-management-context/read/application/use-cases/admin/providers-retrieval/selectors/provider-list-selectors";
import { Skeleton } from "@components/ui/skeleton";
import { adminSelectProviderTrainingsRetrievalForList } from "@academy-context/read/application/use-cases/admin/provider-trainings-retrieval/selectors/provider-training-list-selectors";
import { adminSelectProviderBatchesRetrievalForBatchesPage } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/selectors/provider-batch-list-selectors";
import { BATCH_STATUS } from "@academy-context/read/application/use-cases/shared/selectors/format-batches-for-list";
import { useAdminStudentEnrolled } from "@academy-context/primary/admin/provider-batches/use-student-enrolled";
import { useAdminProviderBatchCreated } from "@academy-context/primary/admin/provider-batches/use-batch-created";
import { useAdminProviderBatchUpdated } from "@academy-context/primary/admin/provider-batches/use-batch-updated";
import { AppState } from "@redux/app-state";
import {
  adminResetUploadProviderBatchParticipationInvoice,
  adminUploadProviderBatchParticipationInvoice,
} from "@academy-context/write/application/use-cases/admin/provider-batch-participation-invoice-upload/upload-provider-batch-participation-invoice";
import { EnrollProviderStudentModal } from "@user-management-context/primary/provider/student-enrollment/enroll-provider-student-modal";
import { enrollStudentToProviderBatch } from "@academy-context/write/application/use-cases/provider/provider-batch-enrollment/enroll-student-to-batch";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { ProviderBatchItem } from "@academy-context/read/domain/types/shared/provider-batch";
import { BatchCalendarModal } from "@academy-context/primary/shared/provider-batch/batch-calendar-modal";

export const AdminProviderBatchListContainer = () => {
  const dispatch = useAppDispatch();
  const uploadedInvoice = useAppSelector((state: AppState) => state.adminProviderBatchParticipationInvoiceUpload);
  const { isLoading, batches } = useAppSelector(adminSelectProviderBatchesRetrievalForBatchesPage);
  const { trainings } = useAppSelector(adminSelectProviderTrainingsRetrievalForList);
  const [displayArchived, setDisplayArchived] = useState(true);
  const [filteredBatches, setFilteredBatches] = useState<typeof batches>([]);
  const { isLoading: isLoadingProviders, providers } = useAppSelector(selectProvidersRetrievalForList);
  const [selectedProviderId, setSelectedProviderId] = useState("");
  const [selectedBatch, setSelectedBatch] = useState<Nullable<ProviderBatchItem>>(null);
  const [isCreateBatchModalOpen, setIsCreateBatchModalOpen] = useState(false);
  const [isEnrollmentModalOpen, setIsEnrollmentModalOpen] = useState(false);
  const [isCalendarModalOpen, setIsCalendarModalOpen] = useState(false);

  useEffect(() => {
    dispatch(adminRetrieveProviders());
  }, [dispatch]);

  useEffect(() => {
    const firstElement = providers[0];
    if (firstElement) setSelectedProviderId(firstElement.id);
  }, [providers]);

  useEffect(() => {
    if (selectedProviderId) {
      dispatch(adminRetrieveProviderTrainings(selectedProviderId));
      dispatch(adminRetrieveProviderBatches(selectedProviderId));
    }
  }, [dispatch, selectedProviderId]);

  useEffect(() => {
    if (displayArchived) setFilteredBatches(batches);
    else {
      const activeBatches = batches.filter(batch => batch.status !== BATCH_STATUS.ARCHIVED);
      setFilteredBatches(activeBatches);
    }
  }, [batches, displayArchived]);

  const handleSubmit = (body: CreateProviderBatchBody) => {
    dispatch(createProviderBatch(body));
  };

  const onUploadInvoice = useCallback(
    (invoice: File) => {
      if (selectedProviderId)
        dispatch(
          adminUploadProviderBatchParticipationInvoice({ providerBatchParticipationInvoice: invoice, providerId: selectedProviderId })
        );
    },
    [dispatch, selectedProviderId]
  );

  const onResetUploadInvoice = useCallback(() => {
    dispatch(adminResetUploadProviderBatchParticipationInvoice());
  }, [dispatch]);

  const onEnrollStudent = (body: ProviderStudentEnrollmentToBatchBody) => {
    dispatch(enrollStudentToProviderBatch(body));
  };

  const openEnrollmentModal = (batch: ProviderBatchItem) => {
    setSelectedBatch(batch);
    setIsEnrollmentModalOpen(true);
  };

  const closeEnrollmentModal = () => {
    setSelectedBatch(null);
    setIsEnrollmentModalOpen(false);
  };

  const openCalendarModal = (batch: ProviderBatchItem) => {
    setSelectedBatch(batch);
    setIsCalendarModalOpen(true);
  };

  const closeCalendarModal = () => {
    setSelectedBatch(null);
    setIsCalendarModalOpen(false);
  };

  const openCreateBatchModal = () => {
    setIsCreateBatchModalOpen(true);
  };

  const closeCreateBatchModal = () => {
    setIsCreateBatchModalOpen(false);
  };

  useAdminProviderBatchCreated({ providerId: selectedProviderId });
  useAdminProviderBatchUpdated({ providerId: selectedProviderId });
  useAdminStudentEnrolled({ providerId: selectedProviderId });

  const providerOptions = providers.map(provider => ({
    label: provider.name,
    value: provider.id,
  }));

  return (
    <>
      <CreateBatchModal isOpen={isCreateBatchModalOpen} onClose={closeCreateBatchModal} onSubmit={handleSubmit} trainings={trainings} />
      {selectedBatch && (
        <EnrollProviderStudentModal
          isOpen={isEnrollmentModalOpen}
          onClose={closeEnrollmentModal}
          onSubmit={onEnrollStudent}
          batch={selectedBatch}
        />
      )}
      {selectedBatch && <BatchCalendarModal isOpen={isCalendarModalOpen} onClose={closeCalendarModal} batch={selectedBatch} />}
      <div className="flex items-center justify-between">
        <Button onClick={openCreateBatchModal}>Ajouter une session de formation</Button>
        <div className="flex flex-col gap-2">
          {isLoadingProviders ? (
            <Skeleton className="h-[40px]" />
          ) : (
            <Combobox
              options={providerOptions}
              value={selectedProviderId}
              onChange={value => setSelectedProviderId(value)}
              placeholder="Selectionner prestataire..."
              search={{
                notFoundText: "Pas de prestataire trouvé.",
                commandInputPlaceHolder: "Chercher prestataire...",
              }}
            />
          )}
          <div className="flex items-center space-x-2" onClick={() => setDisplayArchived(!displayArchived)}>
            <Switch checked={displayArchived} />
            <Label>Afficher les sessions archivées</Label>
          </div>
        </div>
      </div>
      <div className="mt-3">
        {isLoading ? (
          <ProviderBatchListSkeleton />
        ) : (
          filteredBatches.map(batch => (
            <div className="mt-4" key={batch.id}>
              <ProviderBatch
                batch={batch}
                uploadedInvoice={uploadedInvoice}
                onUploadInvoice={onUploadInvoice}
                onResetUploadInvoice={onResetUploadInvoice}
                isAdmin
                openCalendarModal={openCalendarModal}
                openEnrollmentModal={openEnrollmentModal}
              />
            </div>
          ))
        )}
      </div>
    </>
  );
};
