import { useState } from "react";
import { cn } from "@shared-kernel/primary/shared/shadcn/utils/utils";
import { CalendarIcon } from "lucide-react";
import { Popover, PopoverContent, PopoverTrigger } from "@shared-kernel/primary/shared/shadcn/ui/popover";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { addMonths, format } from "date-fns";
import { Calendar } from "@shared-kernel/primary/shared/shadcn/ui/calendar";
import { fr } from "date-fns/locale";

interface Props {
  now: Date;
  value: string;
  onChange: (value: Date) => void;
  disabled?: boolean;
  minDate?: Date;
  maxDate?: Date;
  useDefaultMonth?: boolean;
}

const MAX_THRESHOLD_DATE = addMonths(new Date(), 9);

export const PopoverCalendar = ({ value, onChange, disabled = false, minDate, maxDate, now, useDefaultMonth = false }: Props) => {
  const [open, setOpen] = useState(false);
  let maxDateDisabledCondition: (date: Date) => boolean = () => false;
  if (maxDate) {
    maxDateDisabledCondition = (date: Date) => date.getTime() > maxDate.getTime();
  }
  let minDateDisabledCondition: (date: Date) => boolean = () => false;
  if (minDate) {
    minDateDisabledCondition = (date: Date) => date.getTime() < minDate.getTime();
  }

  return (
    <Popover open={open} modal>
      <PopoverTrigger asChild>
        <Button
          variant={"outline"}
          className={cn("w-full max-w-[240px] pl-3 text-left font-normal", !value && "text-muted-foreground")}
          onClick={() => {
            setOpen(!open);
          }}
          disabled={disabled}
        >
          {value ? format(new Date(value), "PPP", { locale: fr }) : <span>Sélectionnez une date</span>}
          <CalendarIcon className="ml-auto size-4 opacity-50" />
        </Button>
      </PopoverTrigger>
      {/* Side is by default "bottom"but we need to add avoidCollisions = false according to documentation: The preferred side of the anchor to render against when open. Will be reversed when collisions occur and avoidCollisions is enabled.
      https://www.radix-ui.com/primitives/docs/components/popover
      */}
      <PopoverContent className="w-auto p-0" align="start" onPointerDownOutside={() => setOpen(false)} avoidCollisions={false}>
        <Calendar
          mode="single"
          selected={new Date(value)}
          onSelect={value => {
            if (value) onChange(value);
            setOpen(false);
          }}
          disabled={date => {
            return (
              date < new Date("1900-01-01") ||
              maxDateDisabledCondition(date) ||
              minDateDisabledCondition(date) ||
              // As a safety net
              date.getTime() > MAX_THRESHOLD_DATE.getTime()
            );
          }}
          today={now}
          initialFocus
          weekStartsOn={1}
          locale={fr}
          {...(useDefaultMonth ? { defaultMonth: new Date(value) } : {})}
        />
      </PopoverContent>
    </Popover>
  );
};
