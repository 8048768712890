import React from "react";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import RichTextLexical from "@shared-kernel/primary/shared/text-editor/rich-text/rich-text-lexical";

export type FormInputs = {
  personalizedEducationalProject: string;
};

export const PersonalizedEducationalProjectField: React.FC = () => {
  const {
    control,
    formState: { defaultValues },
  } = useFormContext<FormInputs>();

  return (
    <FormField
      control={control}
      name="personalizedEducationalProject"
      render={() => (
        <FormItem>
          <FormLabel>Projet pédagogique personnalisé</FormLabel>
          <FormDescription>
            Votre projet pédagogique peut se contruire autour des thématiques suivantes: Travail de technique vocale pure, préparation de
            roles figurant à votre agenda (nom des oeuvres, votre rôle, lieux de diffusion), préparation de concours, d’auditions,
            construction de répertoire, ...
            <br />
            Des exemples sont consultables{" "}
            <a
              href="https://docs.google.com/document/d/1xaSFl4qnhhmpkssGbqEU6iJCqARelCz70ApeTbws2P8/edit?usp=sharing"
              target="_blank"
              rel="noopener noreferrer"
              className="text-red-500"
            >
              ici
            </a>
            .
          </FormDescription>
          <FormControl>
            <RichTextLexical<FormInputs>
              name="personalizedEducationalProject"
              control={control}
              defaultValue={defaultValues?.personalizedEducationalProject}
              placeholder="Saisissez votre projet pédagogique personnalisé..."
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  );
};
