import { useFieldArray, useFormContext } from "react-hook-form";
import { Objective } from "./objective";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { PlusCircle } from "lucide-react";
import { FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { FormInputs } from "./form-validation/funding-request";

export const PersonalizedEducationalProject = () => {
  const { control } = useFormContext<FormInputs>();
  const { append, remove } = useFieldArray({
    control,
    name: "personalizedEducationalProject",
  });

  return (
    <FormField
      control={control}
      name="personalizedEducationalProject"
      render={({ field }) => {
        return (
          <FormItem>
            <FormLabel>Projet pédagogique personnalisé</FormLabel>
            <FormControl className="w-[100px]">
              <>
                {field.value.map(({ title }, index) => {
                  return <Objective key={title.value + index} index={index} onRemove={() => remove(index)} />;
                })}
              </>
            </FormControl>
            <FormMessage />
            <div>
              <Button
                onClick={() =>
                  append({
                    title: { label: "", value: "" },
                    quantifiableObjective: "",
                  })
                }
                className="mt-2"
                type="button"
                variant="outline"
              >
                <PlusCircle className="mr-3" />
                Ajouter un nouvel objectif
              </Button>
            </div>
          </FormItem>
        );
      }}
    />
  );
};
