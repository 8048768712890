import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { SquareArrowOutUpRight } from "lucide-react";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import { cn } from "@components/utils/utils";
import { selectBalanceAdjustmentsRetrievalForList } from "@academy-context/read/application/use-cases/admin/balance-adjustments-retrieval/selectors/balance-adjustment-list-selectors";
import parse from "html-react-parser";

type SelectorReturnType = ReturnType<typeof selectBalanceAdjustmentsRetrievalForList>["data"][number];

const columns: ColumnDef<SelectorReturnType>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "120px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "label",
    header: () => "Type",
    enableSorting: true,
    meta: {
      title: "Type",
      width: "120px",
    },
  },
  {
    accessorKey: "amount",
    header: () => "Montant",
    cell: info => {
      const { amount, isCredited } = info.row.original;
      let transactionSign = "";
      if (isCredited) transactionSign = "+";
      return (
        <span className={cn(isCredited && "font-bold text-success")}>
          {transactionSign}
          {formatToCurrency(amount)}
        </span>
      );
    },
    enableSorting: true,
    meta: {
      title: "Montant",
      width: "90px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Description",
    cell: info => (info.row.original.commentary ? parse(info.row.original.commentary) : null),
    meta: {
      title: "Description",
    },
  },
  {
    accessorKey: "documentUrl",
    header: () => "Document",
    cell: info =>
      info.row.original.documentUrl ? (
        <>
          <a href={info.row.original.documentUrl} target="_blank" rel="noreferrer">
            <SquareArrowOutUpRight size={16} className="cursor-pointer text-blue-600 visited:text-purple-600 dark:text-blue-500" />
          </a>
        </>
      ) : null,
    meta: {
      title: "Document",
      width: "80px",
    },
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: ({ row }) => {
      const { organization } = row.original;
      return ORGANIZATION_NAME[organization].long;
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
];

interface Props {
  balanceAdjustments: SelectorReturnType[];
}

export const BalanceAdjustmentsList = ({ balanceAdjustments }: Props) => {
  return (
    <DataTable
      columns={columns}
      data={balanceAdjustments}
      searchPlaceHolder="Rechercher un ajustement de solde"
      sortField="creationDate"
      order="desc"
    />
  );
};
