import React, { useEffect, useState } from "react";
import { FormControl, FormDescription, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "./form-validation/funding-request";
import { Checkbox } from "@components/ui/checkbox";
import { Input } from "@components/ui/input";

export const PriceFormField: React.FC = () => {
  const formMethods = useFormContext<FormInputs>();
  const { setValue } = formMethods;
  const [hasTravelExpenses, setHasTravelExpenses] = useState(false);

  useEffect(() => {
    if (!hasTravelExpenses) {
      setValue("travelExpenses", undefined);
    }
  }, [hasTravelExpenses, setValue]);

  return (
    <div className="flex justify-between gap-4">
      <FormField
        control={formMethods.control}
        name="trainingPrice"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Prix de la formation</FormLabel>
            <FormControl className="w-[130px]">
              <Input type="number" step="1" min={0} max={10_000} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formMethods.control}
        name="operaOffCommission"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Frais OF (HT)</FormLabel>
            <FormControl className="w-[130px]">
              <Input type="number" step="1" min={0} max={4000} {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={formMethods.control}
        name="travelExpenses"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Frais de déplacement</FormLabel>
            <div className="flex items-center space-x-3 space-y-0">
              <Checkbox
                checked={hasTravelExpenses}
                onCheckedChange={checked => setHasTravelExpenses(Boolean(checked))}
                id="travelExpenses"
              />
              <label htmlFor="travelExpenses">
                <FormDescription>Dossier comprenant des frais de déplacement</FormDescription>
              </label>
            </div>
            {hasTravelExpenses && (
              <>
                <FormControl>
                  <Input type="number" step="1" min={0} max={4000} {...field} />
                </FormControl>
                <FormMessage />
              </>
            )}
          </FormItem>
        )}
      />
    </div>
  );
};
