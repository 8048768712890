import { AdminBudgetExtended } from "../../../read/application/use-cases/admin/budgets-retrieval/selectors/budgets-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import parse from "html-react-parser";

const columns: ColumnDef<AdminBudgetExtended>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "internshipEndDate",
    header: () => "Fin de stage",
    cell: info => formatDateToLocale(info.row.original.internshipEndDate),
    enableSorting: true,
    meta: {
      title: "Fin de stage",
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    enableSorting: true,
    meta: {
      title: "Elève",
    },
  },
  {
    accessorKey: "funder",
    header: () => "Financeur",
    meta: {
      title: "Financeur",
      width: "120px",
    },
  },
  {
    accessorKey: "allocatedAmount",
    header: () => "Montant total",
    cell: info => formatToCurrency(info.row.original.allocatedAmount),
    meta: {
      title: "Montant total",
      width: "80px",
    },
  },
  {
    accessorKey: "operaOffCommission",
    header: () => "OPERA Off",
    cell: info => formatToCurrency(info.row.original.operaOffCommission),
    meta: {
      title: "OPERA Off",
      width: "80px",
    },
  },
  {
    accessorKey: "totalAmount",
    header: () => "Total crédité",
    cell: info => formatToCurrency(info.row.original.totalAmount),
    meta: {
      title: "Total crédité",
      width: "80px",
    },
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    cell: info => (info.row.original.commentary ? parse(info.row.original.commentary) : null),
    meta: {
      title: "Commentaire",
    },
  },
  {
    accessorKey: "externalFundingRequestId",
    header: () => "Module",
    meta: {
      title: "Module",
      width: "100px",
    },
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: ({ row }) => {
      const { organization } = row.original;
      return ORGANIZATION_NAME[organization].long;
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
];

interface Props {
  budgets: AdminBudgetExtended[];
}

export const BudgetsList = ({ budgets }: Props) => {
  return (
    <DataTable
      columns={columns}
      data={budgets}
      sortField="creationDate"
      order="desc"
      searchPlaceHolder="Rechercher un financement"
      displayDataViewOptions
    />
  );
};
