import { CreateFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { array, mixed, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { AFDAS_STUDENT_STATUS, FUNDER, WRITE_FUNDER_WITH_PAYLOAD } from "@academy-context/shared/domain/types/enums/funders";

export const MAX_SPECIFIC_ACCOMODATION_CHARACTERS = 4000;

export type FormInputs = {
  educationalAdvisorType: EDUCATIONAL_ADVISOR;
  educationalAdvisorId: string;
  providerBatchId: string;
  internalTrainingId: string;
  monthlyLessonsCount: number;
  resumeUrl: string;
  bioUrl: string;
  coverLetter: string;
  personalizedEducationalProject: string;
  funder: WRITE_FUNDER_WITH_PAYLOAD;
  secondaryTeachers: { teacherId: string }[];
  questions: { question: string; answer: string; order: number }[];
  specificAccommodation: string;
};

export const defaultValues = {
  educationalAdvisorType: EDUCATIONAL_ADVISOR.TEACHER,
  educationalAdvisorId: "",
  providerBatchId: "",
  internalTrainingId: "",
  monthlyLessonsCount: 0,
  resumeUrl: "",
  bioUrl: "",
  coverLetter: "",
  personalizedEducationalProject: "",
  funder: {
    type: FUNDER.AFDAS,
    payload: {
      id: "",
      password: "",
      studentStatus: AFDAS_STUDENT_STATUS.INTERMITTENT_DU_SPECTACLE,
    },
  },
  secondaryTeachers: [],
  questions: [],
  specificAccommodation: "",
};

const afdasPayloadSchema = object({
  id: string().required(REQUIRED_FIELD),
  password: string().required(REQUIRED_FIELD),
  studentStatus: string().oneOf(Object.values(AFDAS_STUDENT_STATUS)).required(REQUIRED_FIELD),
});

const franceTravailPayloadSchema = object({
  id: string().required(REQUIRED_FIELD),
  postCode: string().required(REQUIRED_FIELD),
});

export const schema = object().shape(
  {
    monthlyLessonsCount: number().required(REQUIRED_FIELD).positive("Le nombre de cours doit être strictement positif"),
    educationalAdvisorType: string().max(255).required(REQUIRED_FIELD).oneOf(Object.values(EDUCATIONAL_ADVISOR)),
    educationalAdvisorId: string()
      .max(255)
      .when("educationalAdvisorType", {
        is: EDUCATIONAL_ADVISOR.TEACHER,
        then: string().nullable(),
        otherwise: string().required(REQUIRED_FIELD),
      }),
    internalTrainingId: string()
      .max(255)
      .when("educationalAdvisorType", {
        is: EDUCATIONAL_ADVISOR.TEACHER,
        then: string().required(REQUIRED_FIELD),
        otherwise: string().nullable(),
      }),
    providerBatchId: string()
      .max(255)
      .when("educationalAdvisorType", {
        is: EDUCATIONAL_ADVISOR.PROVIDER,
        then: string().required(REQUIRED_FIELD),
        otherwise: string().notRequired(),
      }),
    resumeUrl: string().when("bioUrl", {
      is: "",
      then: string().url().max(255).required(REQUIRED_FIELD),
      otherwise: string().url().max(255),
    }),
    bioUrl: string().when("resumeUrl", {
      is: "",
      then: string().url().max(255).required(REQUIRED_FIELD),
      otherwise: string().url().max(255),
    }),
    coverLetter: string().required(REQUIRED_FIELD),
    personalizedEducationalProject: string().required(REQUIRED_FIELD),
    funder: object({
      type: mixed<FUNDER>().oneOf(Object.values(FUNDER)).required(REQUIRED_FIELD),
      payload: mixed().when("type", {
        is: FUNDER.AFDAS,
        then: afdasPayloadSchema,
        otherwise: mixed().when("type", {
          is: FUNDER.FRANCE_TRAVAIL,
          then: franceTravailPayloadSchema,
          otherwise: mixed().notRequired(),
        }),
      }),
    }),
    secondaryTeachers: array().of(object().shape({ teacherId: string().max(255).required(REQUIRED_FIELD) })),
    questions: array().of(
      object().shape({
        question: string().max(255).required(REQUIRED_FIELD),
        answer: string().required(REQUIRED_FIELD),
        order: number().required(REQUIRED_FIELD),
      })
    ),
    specificAccommodation: string().nullable(),
  },
  [["resumeUrl", "bioUrl"]]
);

export const formatFormDataToBodyDataCreateFundingRequest = (fundingRequest: FormInputs): CreateFundingRequestBody => {
  const {
    secondaryTeachers,
    funder,
    educationalAdvisorId,
    educationalAdvisorType,
    providerBatchId,
    resumeUrl,
    bioUrl,
    coverLetter,
    personalizedEducationalProject,
    internalTrainingId,
    questions,
    specificAccommodation,
    monthlyLessonsCount,
  } = fundingRequest;

  let formattedFunder: WRITE_FUNDER_WITH_PAYLOAD;
  if (funder.type === FUNDER.AFDAS) {
    formattedFunder = {
      type: FUNDER.AFDAS,
      payload: {
        id: funder.payload.id,
        password: funder.payload.password,
        studentStatus: funder.payload.studentStatus,
      },
    };
  } else if (funder.type === FUNDER.FRANCE_TRAVAIL) {
    formattedFunder = {
      type: FUNDER.FRANCE_TRAVAIL,
      payload: {
        id: funder.payload.id,
        postCode: funder.payload.postCode,
      },
    };
  } else {
    formattedFunder = { type: funder.type };
  }

  return {
    educationalAdvisor: { id: educationalAdvisorId || null, type: educationalAdvisorType },
    internalTrainingId: internalTrainingId || null,
    providerBatchId: providerBatchId || null,
    resumeUrl: resumeUrl || null,
    bioUrl: bioUrl || null,
    coverLetter: coverLetter,
    personalizedEducationalProject: personalizedEducationalProject,
    funder: formattedFunder,
    secondaryTeachers: secondaryTeachers.map(teacher => teacher.teacherId),
    questions,
    specificAccommodation: specificAccommodation || null,
    monthlyLessonsCount,
  };
};
