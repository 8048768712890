import { adminRetrieveProviderBatches } from "@academy-context/read/application/use-cases/admin/provider-batches-retrieval/retrieve-provider-batches";
import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { EDUCATIONAL_ADVISOR } from "@academy-context/shared/domain/types/enums/education-advisors";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { ReviewFundingRequestBody } from "@shared-kernel/application/ports/shared/funding-request-repository";
import { ProviderListItem } from "@user-management-context/read/domain/types/admin/provider";
import { TeacherListElement } from "@user-management-context/read/domain/types/admin/teacher";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { commonAdapters } from "src/common-adapters";
import {
  FormInputs,
  schema,
  formatFormDataToBodyDataReviewFundingRequest,
  formatFundingRequestDataToFormData,
} from "./form-validation/funding-request";

interface LogicProps {
  onSubmit: (body: ReviewFundingRequestBody) => void;
  fundingRequest: FundingRequestVM;
  providers: ProviderListItem[];
  teachers: TeacherListElement[];
}

export function useFundingRequestFormLogic({ onSubmit, fundingRequest, providers, teachers }: LogicProps) {
  const dispatch = useAppDispatch();
  const { dateProvider } = commonAdapters;
  const now = useMemo(() => dateProvider!.now(), [dateProvider]);

  const defaultValues = useMemo(() => {
    return formatFundingRequestDataToFormData(fundingRequest, now);
  }, [fundingRequest, now]);

  // track whether an async action is in progress
  const { processing } = useAppSelector(state => state.fundingRequestReview);
  const isProcessing = processing === "pending";

  // manage the alert modal in local state
  const [isModalOpen, setIsModalOpen] = useState(false);

  // handle needsAccommodation
  const [needsAccommodation, setNeedsAccommodation] = useState(Boolean(defaultValues.reviewedSpecificAccommodation));

  // form logic with default values
  const formMethods = useForm<FormInputs>({
    resolver: yupResolver(schema),
    defaultValues,
  });
  const { watch, setValue } = formMethods;

  // watchers
  const reviewedEducationalAdvisorId = watch("reviewedEducationalAdvisorId");
  const reviewedEducationalAdvisorType = watch("reviewedEducationalAdvisorType");
  const reviewedSpecificAccommodation = watch("reviewedSpecificAccommodation");
  const isProvider = reviewedEducationalAdvisorType === EDUCATIONAL_ADVISOR.PROVIDER;

  useEffect(() => {
    if (isProvider && reviewedEducationalAdvisorId) dispatch(adminRetrieveProviderBatches(reviewedEducationalAdvisorId));
  }, [dispatch, isProvider, reviewedEducationalAdvisorId]);

  // handle needsAccommodation changes
  useEffect(() => {
    if (!needsAccommodation) {
      setValue("reviewedSpecificAccommodation", "", { shouldDirty: false });
    }
  }, [needsAccommodation, setValue]);

  useEffect(() => {
    setNeedsAccommodation(Boolean(reviewedSpecificAccommodation));
  }, [reviewedSpecificAccommodation]);

  // address and additionalAddress updates
  useEffect(() => {
    if (reviewedEducationalAdvisorId) {
      let address = "";
      let additionalAddress = "";
      if (isProvider) {
        const provider = providers.find(p => p.id === reviewedEducationalAdvisorId);
        address = provider?.address ?? "";
        additionalAddress = provider?.additionalAddress ?? "";
      } else {
        const teacher = teachers.find(t => t.teacherId === reviewedEducationalAdvisorId);
        address = teacher?.address ?? "";
        additionalAddress = teacher?.additionalAddress ?? "";
      }
      setValue("address", address);
      setValue("additionalAddress", additionalAddress);
    }
  }, [isProvider, providers, reviewedEducationalAdvisorId, setValue, teachers]);

  // final submit
  const handleFormSubmit = (formBody: FormInputs) => {
    const body = formatFormDataToBodyDataReviewFundingRequest(formBody);
    onSubmit(body);
  };

  return {
    handleFormSubmit,
    isProcessing,
    isModalOpen,
    setIsModalOpen,
    formMethods,
    needsAccommodation,
    setNeedsAccommodation,
    now,
    defaultValues,
  };
}
