import { useEffect } from "react";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { adminRetrieveTeachers } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/retrieve-teachers";
import { selectTeachersRetrievalForList } from "../../../../user-management-context/read/application/use-cases/admin/admin-teachers-retrieval/selectors/teachers-list-selectors";
import { Link, useNavigate } from "react-router-dom";
import { ColumnDef } from "@tanstack/react-table";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { Badge } from "@components/ui/badge";
import parse from "html-react-parser";

type SelectorReturnType = ReturnType<typeof selectTeachersRetrievalForList>["data"][number];

const linkWrapper = (id: string, value: string | JSX.Element | JSX.Element[] | null) => (
  <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].teacher.list}/${id}`}>
    <div className="size-full">
      <span>{value}</span>
    </div>
  </Link>
);

const columns: ColumnDef<SelectorReturnType>[] = [
  {
    accessorKey: "name",
    header: () => "Prénom",
    cell: info => linkWrapper(info.row.original.teacherId, info.row.original.name),
    enableSorting: true,
    meta: {
      title: "Prénom",
      width: "150px",
    },
  },
  {
    accessorKey: "lastName",
    header: () => "Nom de famille",
    cell: info => linkWrapper(info.row.original.teacherId, info.row.original.lastName),
    enableSorting: true,
    meta: {
      title: "Nom de famille",
      width: "150px",
    },
  },
  {
    accessorKey: "siret",
    header: () => "Siret / NDA",
    cell: info => {
      const { badges, teacherId } = info.row.original;
      return (
        <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].teacher.list}/${teacherId}`}>
          <div className="flex space-x-1">
            {badges.map(badge => (
              <Badge className="bg-green-500 hover:bg-green-500" key={teacherId + badge}>
                {badge}
              </Badge>
            ))}
          </div>
        </Link>
      );
    },
    meta: {
      title: "Siret / NDA",
      width: "150px",
    },
  },
  {
    accessorKey: "email",
    header: () => "Email",
    cell: info => linkWrapper(info.row.original.teacherId, info.row.original.email),
    enableSorting: true,
    meta: {
      title: "Email",
      width: "300px",
    },
  },
  {
    accessorKey: "adminNotes",
    header: () => "Commentaire",
    cell: info => {
      const { adminNotes } = info.row.original;
      const data = adminNotes ? parse(adminNotes) : null;
      return linkWrapper(info.row.original.teacherId, data);
    },
    enableSorting: true,
    meta: {
      title: "Commentaire",
    },
  },
];

export const TeachersList = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { data: teachers } = useAppSelector(selectTeachersRetrievalForList);

  useEffect(() => {
    dispatch(adminRetrieveTeachers());
  }, [dispatch]);

  const onNewTeacher = () => {
    navigate(ROLE_BASED_URLS[ROLES.ADMIN].teacher.create);
  };

  return (
    <CustomCard
      title="Professeurs"
      headerChildren={
        <div>
          <Button onClick={onNewTeacher}>Nouveau professeur</Button>
        </div>
      }
    >
      <DataTable
        columns={columns}
        data={teachers}
        sortField="lastName"
        order="desc"
        searchPlaceHolder="Rechercher un professeur"
        pageSize={50}
      />
    </CustomCard>
  );
};
