import { CreateBudgetBody } from "@academy-context/write/domain/types/admin/budget";
import { boolean, number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";

export const MIN_FUNDING_AMOUNT = 1;
export const MAX_FUNDING_AMOUNT = 10_000;

export type FormInputs = {
  fundingRequestId: string;
  isAfdasISFunder: boolean;
  obtentionDate: string;
  internshipEndDate: string;
  allocatedAmount: number;
  operaOffCommission: number;
  expirationTimeInMonths?: number;
  commentary: string;
  externalFundingRequestId: string;
  transmitNotificationToAdmin: boolean;
};

export const defaultValues = {
  obtentionDate: "",
  internshipEndDate: "",
  allocatedAmount: 2400,
  operaOffCommission: 550,
  expirationTimeInMonths: undefined,
  commentary: "",
  externalFundingRequestId: "",
  fundingRequestId: "",
  isAfdasISFunder: false,
  transmitNotificationToAdmin: false,
};

export const schema = object().shape({
  obtentionDate: string().max(255).required(REQUIRED_FIELD),
  internshipEndDate: string().max(255).required(REQUIRED_FIELD),
  allocatedAmount: number()
    .typeError("Une valeur est nécessaire")
    .min(MIN_FUNDING_AMOUNT, `minimum ${MIN_FUNDING_AMOUNT}€`)
    .max(MAX_FUNDING_AMOUNT, `maximum ${MAX_FUNDING_AMOUNT}€`)
    .required(REQUIRED_FIELD),
  operaOffCommission: number()
    .typeError("Une valeur est nécessaire")
    .min(0, "minimum 0€")
    .max(4000, "maximum 4000€")
    .required(REQUIRED_FIELD),
  expirationTimeInMonths: number().when("isAfdasISFunder", {
    is: true,
    then: number().required(REQUIRED_FIELD),
    otherwise: number().nullable(),
  }),
  commentary: string().nullable(),
  externalFundingRequestId: string().max(20).required(REQUIRED_FIELD),
  fundingRequestId: string().required(REQUIRED_FIELD).max(255),
  isAfdasISFunder: boolean().required(REQUIRED_FIELD),
  transmitNotificationToAdmin: boolean().required(REQUIRED_FIELD),
});

export const formatFormDataToBodyData = (budget: FormInputs): CreateBudgetBody => {
  return {
    obtentionDate: new Date(budget.obtentionDate),
    internshipEndDate: new Date(budget.internshipEndDate),
    allocatedAmount: budget.allocatedAmount,
    operaOffCommission: budget.operaOffCommission,
    expirationTimeInMonths: budget.expirationTimeInMonths ?? null,
    commentary: budget.commentary || null,
    externalFundingRequestId: budget.externalFundingRequestId,
    fundingRequestId: budget.fundingRequestId,
    transmitNotificationToAdmin: budget.transmitNotificationToAdmin,
  };
};
