import { FormatToolbarPlugin } from "./format-toolbar";
import { ListToolbarPlugin } from "./list-toolbar";
import { HistoryToolbarPlugin } from "./history-toolbar";
import { AlignmentToolbarPlugin } from "./alignment-toolbar";
export function ToolbarPlugin(): JSX.Element {
  return (
    <div className="flex items-center gap-1 rounded-t-md border-b border-gray-200 bg-white p-2">
      <HistoryToolbarPlugin />
      <FormatToolbarPlugin />
      <ListToolbarPlugin />
      <AlignmentToolbarPlugin />
    </div>
  );
}
