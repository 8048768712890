import { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@shared-kernel/primary/shared/shadcn/ui/dialog";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Form, FormControl, FormField, FormItem, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import {
  ProviderBatchFormInputs,
  providerBatchSchema,
  defaultProviderBatchValues,
  formatFormDataToBodyData,
} from "@academy-context/primary/shared/provider-batch/form-validation/batch";
import { CreateProviderBatchBody } from "@user-management-context/shared/application/ports/provider-repository";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { ProviderTraining } from "@academy-context/read/domain/types/shared/provider-training";
import { commonAdapters } from "src/common-adapters";
import { ScrollableDialogContent } from "@components/ui/scrollable-dialog";
import { TrainingDaysForm } from "@academy-context/primary/shared/trainings/training-days.component";
import { addMonths } from "date-fns";

interface Props {
  trainings: ProviderTraining[];
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (body: CreateProviderBatchBody) => void;
}

export const CreateBatchModal = ({ isOpen, onClose, onSubmit, trainings }: Props) => {
  const { dateProvider } = commonAdapters;
  // Otherwise triggers an infinite rendering loop
  const now = useMemo(() => dateProvider!.now(), [dateProvider]);
  // We start at M+2 because this process is usually done 2 months in advance
  const startDate = addMonths(now, 2);

  const form = useForm<ProviderBatchFormInputs>({ resolver: yupResolver(providerBatchSchema), defaultValues: defaultProviderBatchValues });
  const {
    handleSubmit,
    reset,
    formState: { isDirty },
    watch,
  } = form;

  const handleOnSubmit = (formBody: ProviderBatchFormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
    onClose();
  };

  useEffect(() => {
    if (!isOpen) {
      reset();
    }
  }, [reset, isOpen]);

  const trainingId = watch("trainingId");
  const trainingOptions: ComboboxOptions[] = trainings.map(training => ({ label: training.title, value: training.id }));

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <ScrollableDialogContent onPointerDownOutside={onClose} className="space-y-2 sm:max-w-[1100px]">
        <DialogHeader>
          <DialogTitle>Créer une session</DialogTitle>
          <DialogDescription />
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={handleSubmit(handleOnSubmit)} className="space-y-4">
            <div className="space-y-2">
              <Label htmlFor="trainingId" className="text-right">
                Formation
              </Label>
              <FormField
                control={form.control}
                name="trainingId"
                render={({ field }) => (
                  <FormItem className="w-[700px]">
                    <FormControl>
                      <Combobox
                        options={trainingOptions}
                        value={trainingId}
                        onChange={value => field.onChange(value)}
                        placeholder="Selectionner formation..."
                        search={{
                          notFoundText: "Pas de formation trouvée.",
                          commandInputPlaceHolder: "Chercher formation...",
                        }}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <TrainingDaysForm initialStartDate={startDate} now={now} formContext="provider_batch" numberOfMonthsDisplayed={4} />
            <DialogFooter>
              <Button onClick={onClose} variant="outline" type="button">
                Annuler
              </Button>
              <Button type="submit" disabled={!isDirty}>
                Valider
              </Button>
            </DialogFooter>
          </form>
        </Form>
      </ScrollableDialogContent>
    </Dialog>
  );
};
