import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { selectRequestRefund, useRefundRequest } from "./use-refund-request-create";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { LostDataModal } from "@shared-kernel/primary/shared/lost-data-modal/modal";
import { CustomCard } from "@shared-kernel/primary/shared/custom-card/custom-card";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@shared-kernel/primary/shared/shadcn/ui/form";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { RefundRequestBody } from "@academy-context/write/domain/types/admin/refund-request";
import {
  FormInputs,
  schema,
  defaultValues,
  formatFormDataToBodyData,
  MAX_REFUND_REQUEST_PAYMENT_LABEL_CHARACTERS,
} from "@academy-context/primary/student/refund-request-detail/form-validation/refund-request";
import { Invoice } from "@academy-context/primary/student/refund-request-detail/invoice";
import { PlusCircle } from "lucide-react";
import { v4 } from "uuid";
import {
  resetUploadInvoice,
  resetUploadInvoiceForId,
} from "@academy-context/write/application/use-cases/student/invoice-upload/upload-invoice";
import { resetRequestRefund } from "@academy-context/write/application/use-cases/student/refund-request/request-refund";
import { Separator } from "@shared-kernel/primary/shared/shadcn/ui/separator";
import { formatToCurrency } from "@utils/formatting";
import { useFormBlocker } from "@shared-kernel/primary/shared/lost-data-modal/use-form-blocker";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  onSubmit: (refundRequest: RefundRequestBody) => void;
}

export const RefundRequestCreate = ({ onSubmit }: Props) => {
  const { fetching } = useAppSelector(selectRequestRefund);
  const dispatch = useAppDispatch();
  const [shouldBlockNavigation, setShouldBlockNavigation] = useState<boolean>(false);
  const { isModalOpen, handleCloseModal, handleProceed } = useFormBlocker(shouldBlockNavigation);

  const form = useForm<FormInputs>({ resolver: yupResolver(schema), defaultValues });

  const {
    control,
    reset,
    formState: { isDirty, defaultValues: formDefaultValues, isSubmitSuccessful },
    watch,
  } = form;

  const { append, remove, update } = useFieldArray({
    control,
    name: "invoices",
  });

  useEffect(() => {
    setShouldBlockNavigation(isDirty);
  }, [isDirty]);

  const onReset = () => {
    reset();
    dispatch(resetUploadInvoice());
    dispatch(resetRequestRefund());
  };

  useRefundRequest({ onReset });

  const handleOnSubmit = async (formBody: FormInputs) => {
    setShouldBlockNavigation(false);
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const invoices = watch("invoices");
  const sum = invoices.reduce((acc, curr) => acc + Number(curr.amount), 0);

  return (
    <CustomCard title="Demande de remboursement de frais">
      <LostDataModal isOpen={isModalOpen} onClose={handleCloseModal} onSubmit={handleProceed} />
      <Form {...form}>
        <form onSubmit={form.handleSubmit(handleOnSubmit)} className="grid w-full grid-cols-7 gap-4">
          <div className="col-span-full mb-4">
            <p>
              Pour vos demandes de remboursement, veuillez remplir le formulaire suivant.
              <br />
              Merci de grouper toutes vos factures en une seule demande.
            </p>
          </div>
          <div className="col-span-3 space-y-4">
            <FormField
              control={form.control}
              name="iban"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>IBAN (zone Euro)</FormLabel>
                  <FormControl>
                    <Input placeholder={`Exemple : FR76 4061 8803 0000 0408 3645 139`} {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="paymentLabel"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Label du virement ({MAX_REFUND_REQUEST_PAYMENT_LABEL_CHARACTERS} catactères max)</FormLabel>
                  <FormControl>
                    <Input {...field} type="text" />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <FormField
              control={form.control}
              name="commentary"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Description</FormLabel>
                  <FormControl>
                    <PlainTextLexical<FormInputs>
                      name={field.name}
                      control={form.control}
                      defaultValue={formDefaultValues?.[field.name]}
                      isSubmitSuccessful={isSubmitSuccessful}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
          </div>
          <div className="col-span-4 space-y-4">
            <FormField
              control={control}
              name="invoices"
              render={({ field }) => {
                return (
                  <FormItem>
                    <FormLabel>Factures</FormLabel>
                    <FormControl className="w-[100px]">
                      <>
                        {field.value.map((invoice, index) => {
                          const amount = watch(`invoices.${index}.amount`);
                          const onAddInvoice = (url: string) => {
                            update(index, { ...invoice, amount, url });
                          };

                          const onDeleteInvoice = (id: string) => {
                            dispatch(resetUploadInvoiceForId(id));
                            update(index, { ...invoice, amount, url: null });
                          };
                          return (
                            <Invoice
                              key={index}
                              index={index}
                              onRemove={() => remove(index)}
                              onAddInvoice={onAddInvoice}
                              invoice={invoice}
                              onDeleteInvoice={onDeleteInvoice}
                            />
                          );
                        })}
                      </>
                    </FormControl>
                    <FormMessage />
                    <div>
                      <Button onClick={() => append({ id: v4(), amount: 0, url: "" })} className="mt-2" type="button">
                        <PlusCircle className="mr-3" />
                        Ajouter une facture
                      </Button>
                    </div>
                  </FormItem>
                );
              }}
            />
            <Separator className="bg-gray-500" />
            <div className="flex justify-between">
              <p>Total</p> <p>{formatToCurrency(sum)}</p>
            </div>
          </div>
          <div className="col-span-full mt-2 flex justify-end">
            <Button type="submit" disabled={!isDirty || fetching === "pending"}>
              Sauvegarder
            </Button>
          </div>
        </form>
      </Form>
    </CustomCard>
  );
};
