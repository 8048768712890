import { CreateLessonBody } from "@academy-context/write/domain/types/admin/lesson";
import { AdminLessonListVM, Lesson } from "@academy-context/read/domain/types/admin/lesson";
import { StudentLesson } from "@academy-context/read/domain/types/student/lesson";
import { LessonExtended, TeacherLessonListVM } from "@academy-context/read/domain/types/teacher/lesson";
import { PLACE_VALUE } from "@academy-context/read/domain/services/locations";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

const lesson: Lesson = {
  date: "2022-06-02",
  creationDate: "2022-07-09 10:23:14.467+02",
  studentId: "studentId",
  teacherId: "teacherId",
  hours: 2,
  hourlyPrice: 70,
  place: "lyon",
  commentary: "Très bon élève",
  createdAt: "2022-06-02T12:00:00.000Z",
  id: "123abc",
};
const lesson2: Lesson = {
  date: "2022-07-03",
  creationDate: "2022-07-09 10:23:14.467+02",
  studentId: "studentId2",
  teacherId: "teacherId2",
  hours: 10,
  hourlyPrice: 60,
  place: "lyon",
  commentary: "Très bon élève",
  id: "456def",
  createdAt: "2022-06-01T12:00:00.000Z",
};
const lesson3: Lesson = {
  date: "2022-09-02",
  creationDate: "2022-07-09 10:23:14.467+02",
  studentId: "studentId3",
  teacherId: "teacherId3",
  hours: 2,
  hourlyPrice: 50,
  place: "lyon",
  commentary: "Très bon élève",
  id: "lessonId",
  createdAt: "2022-06-08T12:00:00.000Z",
};

export const lessons = [lesson, lesson2, lesson3];

export const existingStudentLesson: StudentLesson = {
  id: "1",
  creationDate: "2022-03-22",
  date: "2022-03-22",
  teacher: "Youssef Farkhani",
  hours: 2,
  hourlyPrice: 40,
  place: {
    name: PLACE_VALUE.PERSO,
    price: null,
    paidByTeacher: true,
  },
  amountChargedOnStudentBalance: 2 * 40,
  isUnderConvention: false,
};
export const existingStudentLesson2: StudentLesson = {
  id: "2",
  creationDate: "2022-03-02",
  date: "2022-03-02",
  teacher: "Youssef Farkhani",
  hours: 3,
  hourlyPrice: 40,
  place: {
    name: PLACE_VALUE.ACCORD_PARFAIT,
    price: 50,
    paidByTeacher: false,
  },
  amountChargedOnStudentBalance: 3 * (40 + 50),
  isUnderConvention: false,
};
export const existingStudentLesson3: StudentLesson = {
  id: "2",
  creationDate: "2022-03-01",
  date: "2022-03-01",
  teacher: "Youssef Farkhani",
  hours: 3,
  hourlyPrice: 40,
  place: {
    name: null,
    price: 50,
    paidByTeacher: true,
  },
  amountChargedOnStudentBalance: 3 * (40 + 50),
  isUnderConvention: false,
};

export const existingAdminLessonListVM: AdminLessonListVM = {
  id: "8",
  creationDate: "2022-03-22T00:00:00.000Z",
  date: "2022-03-22T00:00:00.000Z",
  student: {
    id: "studentId",
    name: "Thierry",
    lastName: "Breton",
  },
  teacher: { id: "teacherId", fullName: "Gérard Darmon" },
  hours: 2,
  hourlyPrice: 60,
  place: {
    name: PLACE_VALUE.CITÉ_INTERNATIONALE_PARIS,
    price: 50,
    paidByTeacher: false,
  },
  amountChargedOnStudentBalance: 2 * (60 + 50),
  paidToTeacher: 2 * 60,
  commentary: "",
  isUnderConvention: false,
  isUnderOldConvention: false,
  organization: ORGANIZATION.OPERA_OFF,
};
export const existingAdminLessonListVM2: AdminLessonListVM = {
  id: "6",
  creationDate: "2022-03-02T00:00:00.000Z",
  date: "2022-03-02T00:00:00.000Z",
  student: {
    id: "studentId",
    name: "Youssef",
    lastName: "Farkhani",
  },
  teacher: { id: "teacherId", fullName: "Gérard Darmon" },
  hours: 2,
  hourlyPrice: 65,
  place: {
    name: PLACE_VALUE.BERLIOZ_NICE,
    price: 50,
    paidByTeacher: false,
  },
  amountChargedOnStudentBalance: 2 * (65 + 50),
  paidToTeacher: 2 * 65,
  commentary: "",
  isUnderConvention: false,
  isUnderOldConvention: false,
  organization: ORGANIZATION.OPERA_OFF,
};

export const createLessonBody: CreateLessonBody = {
  date: "2022-06-02",
  studentId: "studentId",
  teacherId: "teacherId",
  hours: { quantity: 3, hourlyPrice: 80 },
  place: {
    id: PLACE_VALUE.ACCORD_PARFAIT,
    price: 50,
  },
  commentary: "Très bon élève",
};

export const existingTeacherLessonListVM: TeacherLessonListVM = {
  id: "1",
  creationDate: "2022-06-09 10:23:14.467+02",
  date: "2022-06-02",
  student: { id: "studentId", fullName: "Youssef Farkhani" },
  hours: 2,
  hourlyPrice: 70,
  place: {
    name: PLACE_VALUE.BEAUMARCHAIS,
    price: 50,
    paidByTeacher: false,
  },
  commentary: "Très bon élève",
  paidToTeacher: 2 * 70,
  isUnderConvention: false,
};
export const existingTeacherLessonListVM2: TeacherLessonListVM = {
  id: "2",
  creationDate: "2022-07-09 10:23:14.467+02",
  date: "2022-06-03",
  student: { id: "studentId", fullName: "Youssef Farkhani" },
  hours: 3,
  hourlyPrice: 60,
  place: {
    name: null,
    price: 50,
    paidByTeacher: true,
  },
  commentary: "Très bon élève",
  paidToTeacher: 3 * 60,
  isUnderConvention: false,
};
export const existingTeacherLessonListVM3: TeacherLessonListVM = {
  id: "3",
  creationDate: "2022-08-09 10:23:14.467+02",
  date: "2022-06-04",
  student: { id: "studentId", fullName: "Youssef Farkhani" },
  hours: 2,
  hourlyPrice: 30,
  place: {
    name: PLACE_VALUE.PERSO,
    price: null,
    paidByTeacher: true,
  },
  commentary: "Très bon élève",
  paidToTeacher: 2 * 30,
  isUnderConvention: true,
};

export const existingLessonExtended: LessonExtended = {
  ...existingTeacherLessonListVM,
  studio: "Beaumarchais",
  localeCreationDate: "09/06/2022",
  localeDate: "02/06/2022",
};
export const existingLessonExtended2: LessonExtended = {
  ...existingTeacherLessonListVM2,
  studio: "50 €/heure: Payé par le professeur",
  localeCreationDate: "09/07/2022",
  localeDate: "03/06/2022",
};
export const existingLessonExtended3: LessonExtended = {
  ...existingTeacherLessonListVM3,
  studio: "Studio non payant (perso)",
  localeCreationDate: "09/08/2022",
  localeDate: "04/06/2022",
};
