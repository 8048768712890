import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { AppState } from "@redux/app-state";

export const selectBudgetsRetrievalForList = (state: AppState) => {
  const {
    studentBudgetsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.createdAt).getTime();
    const dateBInMs = new Date(b.createdAt).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData.map(b => {
      return {
        ...b,
        funder: FUNDER_LABELS[b.funder],
        localeCreatedAtDate: new Date(b.createdAt).toLocaleDateString("fr-FR"),
      };
    }),
    fetching,
  };
};
