import { Separator } from "@components/ui/separator";
import { SidebarTrigger } from "@components/ui/sidebar";
import { useInterval } from "@hooks/useInterval";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { CommandSearch, Option } from "@shared-kernel/primary/shared/command-search";
import { statusFormatter } from "@shared-kernel/primary/shared/student-status-formatter";
import { retrieveStudentsForSearchBar } from "@user-management-context/read/application/use-cases/admin/students-retrieval-for-search-bar/retrieve-students-for-search-bar";
import { selectStudentsForSearchBar } from "@user-management-context/read/application/use-cases/admin/students-retrieval-for-search-bar/selectors/students-selector-for-search-bar";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { formatDateToLocale, formatToCurrency } from "@utils/formatting";
import { getNormalizedString, sortAlphabetically } from "@utils/utils";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { ROLE_BASED_URLS } from "src/routes";

export const HeaderBar = () => {
  const dispatch = useAppDispatch();
  const { students, isLoading } = useAppSelector(selectStudentsForSearchBar);

  // Retrieve students every 5 minutes so the balance in the search bar is almost up-to-date compared to the student details
  useInterval(
    () => {
      dispatch(retrieveStudentsForSearchBar());
    },
    5 * 60000,
    { runOnInit: true }
  );

  const options: Option[] = useMemo(() => {
    const orderedStudents = [...students].sort((a, b) => sortAlphabetically(a.name, b.name));
    return orderedStudents.map(student => ({
      display: (
        <Link
          to={`${ROLE_BASED_URLS[ROLES.ADMIN].student.list}/${student.studentId}`}
          key={student.studentId}
          className="flex items-center"
        >
          {statusFormatter(student)}
          <span className="ml-2">
            {`${student.name} ${student.lastName} | ${formatToCurrency(student.balance.global)}${
              student.nextFundingDate ? ` | ${formatDateToLocale(student.nextFundingDate)}` : ""
            }`}
          </span>
        </Link>
      ),
      value: `${getNormalizedString(student.name)} ${getNormalizedString(student.lastName)}`,
    }));
  }, [students]);

  return (
    <div className="mb-4 bg-sidebar">
      <div className="flex items-center p-2">
        <SidebarTrigger />
        <div className="ml-14 w-full md:w-auto">
          <CommandSearch options={options} emptyMessage="Pas de résultat." placeholder="Rechercher élève ..." isLoading={isLoading} />
        </div>
      </div>
      <Separator />
    </div>
  );
};
