import { AppState } from "@redux/app-state";

export const selectTransfersRetrievalForList = (state: AppState) => {
  const {
    transferListRetrieval: { data, fetching },
  } = state;

  return {
    data: data.map(payment => {
      const { teacher, from } = payment;

      const { siret, activityRegistrationNumber } = teacher;
      const badges = [];
      if (siret) {
        const isValidSiret = /[^0 ]/.test(siret);
        if (isValidSiret) badges.push("Siret");
      }
      if (activityRegistrationNumber) {
        const isValidActivityRegistrationNumber = /[^0 ]/.test(activityRegistrationNumber);
        if (isValidActivityRegistrationNumber) badges.push("NDA");
      }
      return {
        ...payment,
        teacher: { ...teacher, badges, fullName: `${teacher.name} ${teacher.lastName}` },
        month: new Date(from)
          .toLocaleDateString("fr-FR", { year: "numeric", month: "short", timeZone: "Europe/Paris" })
          .replace(/^\w/, c => c.toUpperCase()),
      };
    }),
    fetching,
  };
};
