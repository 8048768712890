import { DateProvider } from "@shared-kernel/application/ports/date-provider/date-provider";
import { USER_TIMEZONE } from "@user-management-context/read/domain/types/user";
import { toZonedTime, format } from "date-fns-tz";
import bankHolidaysJson from "./private/bank-holidays_2003-2028.json";

// To update the bank holiday file use the following command:
// curl -s https://calendrier.api.gouv.fr/jours-feries/metropole.json | jq 'to_entries | map({date: .key, nom: .value})' > output.json
export class RealDateProvider implements DateProvider {
  private bankHolidaysSet: Set<string>;

  constructor() {
    this.bankHolidaysSet = new Set(bankHolidaysJson.map((holiday: { date: string }) => holiday.date));
  }

  now(): Date {
    return new Date();
  }

  isBankHoliday(date: Date): boolean {
    const zonedDate = toZonedTime(date, USER_TIMEZONE);
    const formattedDate = format(zonedDate, "yyyy-MM-dd", { timeZone: USER_TIMEZONE });

    return this.bankHolidaysSet.has(formattedDate);
  }
}
