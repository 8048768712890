import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { AppState } from "@redux/app-state";
import { isLoadingState } from "@utils/utils";

export type AdminBudgetExtended = ReturnType<typeof selectBudgetsRetrievalForList>["data"][number];

export const selectBudgetsRetrievalForList = (state: AppState) => {
  const {
    budgetsRetrieval: { data, fetching },
  } = state;

  const sortedData = [...data].sort((a, b) => {
    const dateAInMs = new Date(a.internshipEndDate).getTime();
    const dateBInMs = new Date(b.internshipEndDate).getTime();

    if (dateBInMs < dateAInMs) {
      return -1;
    } else if (dateBInMs > dateAInMs) {
      return 1;
    }
    return 0;
  });

  return {
    data: sortedData.map(b => {
      return {
        ...b,
        funder: FUNDER_LABELS[b.funder],
        localeDate: new Date(b.internshipEndDate).toLocaleDateString("fr-FR"),
      };
    }),
    isLoading: isLoadingState(fetching),
  };
};
