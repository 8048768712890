import { ChangeEvent, useEffect, useRef, useState } from "react";
import { CheckCircle2, Loader2, Trash2 } from "lucide-react";
import { FetchingState } from "src/redux/app-state";
import { Label } from "@shared-kernel/primary/shared/shadcn/ui/label";
import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { FileProps } from "@academy-context/write/domain/constants/shared";

interface Props<Type> {
  processing: FetchingState;
  label?: string;
  value: Nullable<string>;
  onChange: (file: File) => void;
  types: string[];
  onError: () => void;
  onDelete?: () => void;
  validatorFunc: (props: Type) => boolean;
  getFileProps: (file: File) => Promise<Type>;
}

const getFileFromInput = (event: ChangeEvent<HTMLInputElement>): File | undefined => {
  let file;
  if (event && event.target && event.target.files && event.target.files.length > 0) {
    file = event.target.files[0];
  }
  return file;
};

export const UploadButton = <Type = FileProps,>({
  processing,
  onChange,
  types,
  value,
  label,
  onDelete,
  validatorFunc,
  onError,
  getFileProps,
}: Props<Type>) => {
  const [currentFileUrl, setCurrentFileUrl] = useState(value);
  const isFilled = Boolean(currentFileUrl);
  const inputFileRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (value !== currentFileUrl) {
      setCurrentFileUrl(value ? value : null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleInputChange = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = getFileFromInput(event);
    if (file) {
      if (!file) return;
      let isValidFile = false;
      try {
        const props = await getFileProps(file);
        isValidFile = validatorFunc(props);
      } catch (err) {
        console.log("catch", err);
      }

      if (isValidFile) {
        onChange(file);
      } else {
        onError();
      }
    }
  };

  const handleDelete = () => {
    if (onDelete) onDelete();
    if (inputFileRef.current) inputFileRef.current.value = "";
  };

  return (
    <div className="space-y-2">
      {label && <Label htmlFor={label}>{label}</Label>}
      <div className="flex items-center">
        <Input id={label} type="file" accept={types.join(",")} onChange={handleInputChange} ref={inputFileRef} name={label} />
        {processing === "pending" && <Loader2 className="ml-2 animate-spin" size={16} />}
        {processing === "success" && <CheckCircle2 className="ml-2" size={30} fill="green" color="white" strokeWidth={1} />}
        {isFilled && onDelete && <Trash2 color="red" onClick={handleDelete} className="ml-2 cursor-pointer" />}
      </div>
    </div>
  );
};
