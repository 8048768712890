import { array, date, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { CreateProviderBatchBody } from "@user-management-context/shared/application/ports/provider-repository";
import { DAY_BLOCK } from "@academy-context/read/domain/types/shared/training-day";

export type ProviderBatchFormInputs = {
  trainingId: string;
  trainingDays: { date: Date; block: DAY_BLOCK }[];
};

export const defaultProviderBatchValues = {
  trainingId: "",
  trainingDays: [],
};

export const providerBatchSchema = object().shape({
  trainingId: string().max(255).required(REQUIRED_FIELD),
  trainingDays: array()
    .min(1, "Requiert au moins une date")
    .of(
      object().shape({
        date: date().required(REQUIRED_FIELD).typeError("Date invalide"),
        block: string().required(REQUIRED_FIELD).oneOf(Object.values(DAY_BLOCK)),
      })
    ),
});

export const formatFormDataToBodyData = (providerBatch: ProviderBatchFormInputs): CreateProviderBatchBody => {
  const { trainingDays, trainingId } = providerBatch;

  return {
    trainingId,
    trainingDays: trainingDays.map(({ date, block }) => ({ date: date.toISOString(), block })),
  };
};
