import { Nullable } from "../../../../../../../../shared-kernel/core/types/nullable";
import { number, object, string } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { PaymentStatus } from "@academy-context/read/domain/types/enums/payment-status";
import { Refund } from "@academy-context/read/domain/types/admin/refunds";
import { UpdateRefundBody } from "@academy-context/write/domain/types/admin/refunds";
import { ORGANIZATION } from "@shared-kernel/domain/organisation";

export type FormInputs = {
  adminCommentary: string;
  status: PaymentStatus;
  paymentDate: Nullable<string>;
  amountPaid: number | undefined;
  organization: Nullable<ORGANIZATION>;
};

export const defaultValues = {
  adminCommentary: "",
  status: PaymentStatus.PENDING,
  paymentDate: new Date().toISOString(),
  amountPaid: undefined,
  organization: ORGANIZATION.OPERA_OFF,
};

export const schema = object().shape({
  adminCommentary: string().nullable(),
  status: string().required(REQUIRED_FIELD).oneOf(Object.values(PaymentStatus)),
  paymentDate: string().max(255).nullable(),
  amountPaid: number().typeError("Minimum 0 €").min(0, "Minimum 0 €").nullable(),
  organization: string()
    .max(255)
    .when("status", {
      is: PaymentStatus.PAID,
      then: string().required(REQUIRED_FIELD).oneOf(Object.values(ORGANIZATION)),
      otherwise: string().nullable(),
    }),
});

export const formatRefundDataToFormData = (refund: Refund): FormInputs => {
  const { paymentDate, adminCommentary, status, amountPaid, totalAmount, organization } = refund;

  let formattedAmountPaid = undefined;

  if (status === "PAID") {
    formattedAmountPaid = amountPaid ?? undefined;
  } else {
    formattedAmountPaid = totalAmount;
  }

  return {
    ...defaultValues,
    paymentDate,
    adminCommentary: adminCommentary ?? "",
    status,
    amountPaid: formattedAmountPaid,
    organization,
  };
};

export const formatFormDataToBodyData = (transfer: FormInputs): UpdateRefundBody => {
  const { amountPaid, adminCommentary, paymentDate, status, organization } = transfer;
  if (status === PaymentStatus.PAID) {
    return {
      adminCommentary: adminCommentary || null,
      paymentDate: new Date(paymentDate!).toISOString(),
      status: PaymentStatus.PAID,
      amountPaid: amountPaid ?? null,
      organization,
    };
  }

  return {
    adminCommentary: null,
    paymentDate: null,
    status: transfer.status,
    amountPaid: null,
    organization: null,
  };
};
