import { Input } from "@shared-kernel/primary/shared/shadcn/ui/input";
import { ArrowUpRightFromCircle, EyeIcon } from "lucide-react";
import { Combobox, ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { EDUCATIONAL_ADVISOR, EDUCATIONAL_ADVISOR_MAP } from "@academy-context/shared/domain/types/enums/education-advisors";
import { AFDAS_STUDENT_STATUS_OPTIONS, FUNDER } from "@academy-context/shared/domain/types/enums/funders";
import { FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";
import { FundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { Label } from "@components/ui/label";
import parse from "html-react-parser";
import { useState } from "react";

interface Props {
  fundingRequest: FundingRequestVM;
  teacherOptions: ComboboxOptions[];
  providerOptions: ComboboxOptions[];
  internalTrainingsOptions: ComboboxOptions[];
}

export const StudentFundingRequestForm = ({ fundingRequest, teacherOptions, providerOptions, internalTrainingsOptions }: Props) => {
  const {
    funder,
    student,
    educationalAdvisor,
    providerBatch,
    internalTrainingId,
    bioUrl,
    resumeUrl,
    coverLetter,
    personalizedEducationalProject,
    specificAccommodation,
    secondaryTeachers,
    questions,
    monthlyLessonsCount,
  } = fundingRequest;
  const [showPassword, setShowPassword] = useState(false);

  const isProvider = educationalAdvisor.type === EDUCATIONAL_ADVISOR.PROVIDER;

  return (
    <>
      <div className="flex w-full flex-col justify-start space-y-4">
        <div className="space-y-2">
          <Label>Elève</Label>
          <Input value={student.fullName} disabled />
        </div>
        <div className="space-y-2">
          <Label>Type de financeur</Label>
          <Input value={FUNDER_LABELS[funder.type]} disabled />
        </div>
        {funder.type === FUNDER.AFDAS && (
          <>
            <div className="space-y-2">
              <Label>Identifiant AFDAS</Label>
              <Input value={funder.payload.id} disabled />
            </div>
            <div className="space-y-2">
              <Label>Mot de passe AFDAS</Label>
              <div className="relative">
                <EyeIcon
                  className="absolute right-2 top-1/2 z-50 -translate-y-1/2 cursor-pointer"
                  color="black"
                  onClick={() => setShowPassword(!showPassword)}
                />
                <Input value={funder.payload.password ?? ""} disabled type={showPassword ? "text" : "password"} />
              </div>
            </div>
            <div className="space-y-2">
              <Label>Statut de l'étudiant</Label>
              <Input value={AFDAS_STUDENT_STATUS_OPTIONS[funder.payload.studentStatus]} disabled />
            </div>
          </>
        )}
        {funder.type === FUNDER.FRANCE_TRAVAIL && (
          <>
            <div className="space-y-2">
              <Label>Identifiant France Travail</Label>
              <Input value={funder.payload.id} disabled />
            </div>
            <div className="space-y-2">
              <Label>Code postal déclaré à France Travail</Label>
              <Input value={funder.payload.postCode} disabled />
            </div>
          </>
        )}
        <div className="space-y-2">
          <Label>Professeur / Prestataire</Label>
          <Input value={EDUCATIONAL_ADVISOR_MAP[educationalAdvisor.type]} disabled />
          {!educationalAdvisor.id ? (
            <Input value={"Professeur non défini"} disabled />
          ) : isProvider ? (
            <Combobox
              options={providerOptions}
              value={educationalAdvisor.id}
              onChange={() => {}}
              placeholder="Selectionner prestataire..."
              search={{
                notFoundText: "Pas de prestataire trouvé.",
                commandInputPlaceHolder: "Chercher prestataire...",
              }}
              disabled
            />
          ) : (
            <Combobox
              options={teacherOptions}
              value={educationalAdvisor.id}
              onChange={() => {}}
              placeholder="Selectionner professeur..."
              search={{
                notFoundText: "Pas de professeur trouvé.",
                commandInputPlaceHolder: "Chercher professeur...",
              }}
              disabled
            />
          )}
        </div>
        <div className="space-y-2">
          {isProvider ? (
            <>
              <Label>Intitulé de la session</Label>
              <Input value={providerBatch?.title} disabled />
            </>
          ) : (
            <>
              <Label>Intitulé de la formation</Label>
              <Combobox
                options={internalTrainingsOptions}
                value={internalTrainingId ?? ""}
                onChange={() => {}}
                placeholder="Selectionner formation..."
                search={{
                  notFoundText: "Pas de formation trouvé.",
                  commandInputPlaceHolder: "Chercher formation...",
                }}
                disabled
              />
            </>
          )}
        </div>
        <div className="grid grid-cols-2">
          <div className="col-span-1 flex flex-col items-center space-y-2">
            <Label>CV (pdf)</Label>
            {resumeUrl && (
              <a href={resumeUrl} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                <ArrowUpRightFromCircle size={18} />
                <span className="text-sm font-normal">Aperçu</span>
              </a>
            )}
          </div>
          <div className="col-span-1 flex flex-col items-center space-y-2">
            <Label>Bio (pdf)</Label>
            {bioUrl && (
              <a href={bioUrl} target="_blank" rel="noreferrer" className="ml-2 flex flex-col items-center">
                <ArrowUpRightFromCircle size={18} />
                <span className="text-sm font-normal">Aperçu</span>
              </a>
            )}
          </div>
        </div>
        <div className="space-y-2">
          <Label>Lettre de motivation de l'élève</Label>
          <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">{parse(coverLetter)}</div>
        </div>
        <div className="space-y-2">
          <Label>Projet pédagogique personnalisé de l'élève</Label>
          <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
            {parse(personalizedEducationalProject)}
          </div>
        </div>
        {!isProvider && (
          <div className="space-y-2">
            <Label>Professeurs complémentaires</Label>
            {secondaryTeachers.length ? (
              <div className="space-y-2">
                {secondaryTeachers.map(teacher => (
                  <Combobox
                    key={teacher}
                    options={teacherOptions}
                    value={teacher}
                    onChange={() => {}}
                    placeholder="Selectionner professeur..."
                    search={{
                      notFoundText: "Pas de professeur trouvé.",
                      commandInputPlaceHolder: "Chercher professeur...",
                    }}
                    disabled
                  />
                ))}
              </div>
            ) : (
              <div>
                <span className="text-base text-black disabled:opacity-50 dark:text-inherit md:text-sm">
                  Pas de professeurs complémentaires
                </span>
              </div>
            )}
          </div>
        )}
        <div className="space-y-2">
          <Label>Aménagement spécifique</Label>
          {specificAccommodation ? (
            <div className="min-h-[236px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">
              {parse(specificAccommodation)}
            </div>
          ) : (
            <div>
              <span className="text-base text-black disabled:opacity-50 dark:text-inherit md:text-sm">Pas d'aménagement spécifique</span>
            </div>
          )}
        </div>
        <div className="space-y-2">
          <Label>Nombre de cours par mois</Label>
          {monthlyLessonsCount ? (
            <Input value={monthlyLessonsCount} disabled />
          ) : (
            <div>
              <span className="text-base text-black disabled:opacity-50 dark:text-inherit md:text-sm">Non spécifié</span>
            </div>
          )}
        </div>
        <div className="space-y-2">
          <Label>Questionnaire de positionnement</Label>
          {questions.length ? (
            <>
              {questions.map(({ question, order, answer }) => (
                <div key={order} className="space-y-2">
                  <Label className="font-normal">{question}</Label>
                  <div className="min-h-[50px] rounded-md border border-[#ced4da] bg-[#e9ecef] px-2 py-3 text-sm">{parse(answer)}</div>
                </div>
              ))}
            </>
          ) : (
            <div>
              <span className="text-base text-black disabled:opacity-50 dark:text-inherit md:text-sm">Pas de questionnaire</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
