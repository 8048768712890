import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { INSERT_ORDERED_LIST_COMMAND, INSERT_UNORDERED_LIST_COMMAND, ListNode } from "@lexical/list";
import { $getSelection, $isRangeSelection } from "lexical";
import { useState, useEffect } from "react";
export function ListToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [isUnorderedList, setIsUnorderedList] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(false);

  useEffect(() => {
    return editor.registerUpdateListener(({ editorState }) => {
      editorState.read(() => {
        const selection = $getSelection();
        if (!$isRangeSelection(selection)) return;

        const nodes = selection.getNodes();
        if (nodes.length === 0) return;

        const node = nodes[0];
        if (!node) return;

        const parent = node.getParent();
        if (!parent) return;

        setIsUnorderedList(parent instanceof ListNode && parent.getListType() === "bullet");
        setIsOrderedList(parent instanceof ListNode && parent.getListType() === "number");
      });
    });
  }, [editor]);

  return (
    <div className="flex gap-1 border-r border-gray-200 px-2">
      <button
        onClick={() => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND, undefined)}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Insert Bullet List"
        type="button"
        data-active={isUnorderedList}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1">
          <line x1="9" y1="6" x2="20" y2="6" />
          <line x1="9" y1="12" x2="20" y2="12" />
          <line x1="9" y1="18" x2="20" y2="18" />
          <circle cx="4" cy="6" r="2" />
          <circle cx="4" cy="12" r="2" />
          <circle cx="4" cy="18" r="2" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND, undefined)}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Insert Numbered List"
        type="button"
        data-active={isOrderedList}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="1">
          <line x1="10" y1="4" x2="20" y2="4" />
          <line x1="10" y1="12" x2="20" y2="12" />
          <line x1="10" y1="20" x2="20" y2="20" />
          <text x="2" y="6" fontSize="8" fill="currentColor" fontWeight="100">
            1
          </text>
          <text x="2" y="14" fontSize="8" fill="currentColor" fontWeight="100">
            2
          </text>
          <text x="2" y="22" fontSize="8" fill="currentColor" fontWeight="100">
            3
          </text>
        </svg>
      </button>
    </div>
  );
}
