import { useEffect } from "react";

import { retrieveToReviewFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/to-review-funding-request-list-retrieval/retrieve-to-review-funding-requests";
import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { Link } from "react-router-dom";
import { CellContext, ColumnDef } from "@tanstack/react-table";
import { ToReviewFundingRequestVM } from "@academy-context/read/domain/types/admin/funding-request";
import { formatDateToLocale } from "../../../../utils/formatting";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { ROLE_BASED_URLS } from "src/routes";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";
import { AFDAS_STUDENT_STATUS_LABELS, FUNDER, FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";

const linkWrapper = (id: string, value: string) => (
  <Link to={`${ROLE_BASED_URLS[ROLES.ADMIN].fundingRequest.list}/${id}`}>
    <div className="size-full">
      <span>{value}</span>
    </div>
  </Link>
);

const pendingFundingRequestscolumns: ColumnDef<ToReviewFundingRequestVM>[] = [
  {
    accessorKey: "hasAlreadyBeenReviewed",
    header: () => {},
    cell: () => {},
    enableSorting: false,
    meta: {
      getCellContext: (context: CellContext<ToReviewFundingRequestVM, unknown>) => {
        const isDuplicate = context.getValue();
        return {
          className: isDuplicate ? "bg-theme" : "",
        };
      },
      title: "Retour validation",
      width: "30px",
    },
  },
  {
    accessorKey: "creationDate",
    header: () => "Date demande élève",
    cell: info => linkWrapper(info.row.original.id, formatDateToLocale(info.row.original.creationDate)),
    meta: {
      title: "Date demande élève",
      width: "150px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "funder.type",
    header: () => "Financeur",
    cell: info => {
      const label =
        FUNDER_LABELS[info.row.original.funder.type] +
        (info.row.original.funder.type === FUNDER.AFDAS
          ? ` (${AFDAS_STUDENT_STATUS_LABELS[info.row.original.funder.payload.studentStatus]})`
          : "");
      return linkWrapper(info.row.original.id, label);
    },
    meta: {
      title: "Financeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.name),
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.phone",
    header: () => "Téléphone",
    cell: info => linkWrapper(info.row.original.id, info.row.original.student.phone),
    meta: {
      title: "Téléphone",
    },
  },
];

export const FundingRequestToReviewList = () => {
  const dispatch = useAppDispatch();
  const { data: toReview } = useAppSelector((state: AppState) => state.toReviewFundingRequestsRetrieval);

  useEffect(() => {
    dispatch(retrieveToReviewFundingRequests());
  }, [dispatch]);

  return (
    <Card>
      <CardContent className="space-y-2">
        <DataTable
          columns={pendingFundingRequestscolumns}
          data={toReview}
          sortField="creationDate"
          order="desc"
          searchPlaceHolder="Rechercher une demande de financement"
        />
      </CardContent>
    </Card>
  );
};
