import { useEffect, useRef, useState } from "react";
import { useAppDispatch } from "@redux/hooks";
import { ExtendedConventionListItemVM } from "../../../read/application/use-cases/admin/conventions-retrieval/selectors/conventions-list-selectors";
import { ColumnDef } from "@tanstack/react-table";
import { formatDateToLocale, formatToCurrency } from "../../../../utils/formatting";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { Nullable } from "@shared-kernel/core/types/nullable";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@shared-kernel/primary/shared/shadcn/ui/dropdown-menu";
import { DotsVerticalIcon } from "@radix-ui/react-icons";
import { computeConventionStatus } from "@academy-context/read/domain/services/convention";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { cancelConvention } from "@academy-context/write/application/use-cases/admin/convention-cancellation/cancel-convention";
import { endConvention } from "@academy-context/write/application/use-cases/admin/convention-ending/end-convention";
import { useModal } from "@hooks/useModal";
import { Tooltip } from "@shared-kernel/primary/shared/tooltip/tooltip";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import parse from "html-react-parser";

const columns: ColumnDef<ExtendedConventionListItemVM>[] = [
  {
    accessorKey: "creationDate",
    header: () => "Enregistré le",
    cell: info => formatDateToLocale(info.row.original.creationDate),
    enableSorting: true,
    meta: {
      title: "Enregistré le",
      width: "90px",
    },
  },
  {
    accessorKey: "date",
    header: () => "Date",
    cell: info => formatDateToLocale(info.row.original.date),
    meta: {
      width: "90px",
    },
  },
  {
    accessorKey: "student",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "teacher",
    header: () => "Professeur",
    meta: {
      title: "Professeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "hours",
    header: () => "Heures",
    meta: {
      title: "Heures",
      width: "60px",
    },
  },
  {
    accessorKey: "hourlyPrice",
    header: () => "Tarif horaire",
    cell: info => formatToCurrency(info.row.original.hourlyPrice),
    meta: {
      title: "Tarif horaire",
      width: "65px",
    },
  },
  {
    accessorKey: "hoursLeft",
    header: () => "Heures restantes",
    meta: {
      title: "Heures restantes",
      width: "75px",
    },
  },
  {
    accessorKey: "totalPrice",
    header: () => (
      <div className="flex items-center">
        <span>Prix total estimé</span>
        <Tooltip tooltipContent="Ce prix peut varier si le tarif horaire d'un cours sous convention est modifié" />
      </div>
    ),
    cell: info => formatToCurrency(info.row.original.totalPrice),
    meta: {
      title: "Prix total estimé",
      width: "90px",
    },
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: ({ row }) => {
      const { organization } = row.original;
      return ORGANIZATION_NAME[organization].short;
    },
    meta: {
      title: "Société",
      width: "75px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "commentary",
    header: () => "Commentaire",
    cell: info => (info.row.original.commentary ? parse(info.row.original.commentary) : null),
    meta: {
      title: "Commentaire",
    },
  },
];

interface Props {
  conventions: ExtendedConventionListItemVM[];
}

export const ConventionsList = ({ conventions }: Props) => {
  const dispatch = useAppDispatch();
  const [dataColumns, setDataColumns] = useState<ColumnDef<ExtendedConventionListItemVM>[]>([...columns]);
  const currentSelectedLineInfo = useRef<Nullable<ExtendedConventionListItemVM>>(null);
  const { closeModal: closeCancelModal, isModalOpen: isCancelModalOpen, openModal: openCancelModal } = useModal();
  const { closeModal: closeEndedModal, isModalOpen: isEndedModalOpen, openModal: openEndedModal } = useModal();

  useEffect(() => {
    const cancelColumn: ColumnDef<ExtendedConventionListItemVM> = {
      accessorKey: "id",
      header: () => "Actions",
      enableSorting: false,
      meta: {
        title: "Actions",
        width: "60px",
      },
      cell: ({ row }) => {
        const isRunningConvention = row.original.hoursLeft > 0;
        const hasBeenEnded = Boolean(row.original.prematureEndDate);
        if (!isRunningConvention || hasBeenEnded) {
          return <></>;
        } else
          return (
            <DropdownMenu>
              <DropdownMenuTrigger asChild>
                <Button variant="ghost" className="size-8 p-0">
                  <span className="sr-only">Ouvrir le menu</span>
                  <DotsVerticalIcon className="size-4" />
                </Button>
              </DropdownMenuTrigger>
              <DropdownMenuContent align="end">
                <DropdownMenuItem
                  onClick={() => {
                    openCancelModal();
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Supprimer convention
                </DropdownMenuItem>
                <DropdownMenuSeparator />
                <DropdownMenuItem
                  onClick={() => {
                    openEndedModal();
                    currentSelectedLineInfo.current = row.original;
                  }}
                >
                  Terminer prématurément
                </DropdownMenuItem>
              </DropdownMenuContent>
            </DropdownMenu>
          );
      },
    };
    setDataColumns([
      ...columns,
      cancelColumn,
      {
        accessorKey: "prematureEndDate",
        header: () => "Statut",
        meta: {
          title: "Statut",
          width: "80px",
        },
        cell: ({ row }) => {
          const { prematureEndDate, hoursLeft } = row.original;
          return computeConventionStatus(prematureEndDate, hoursLeft);
        },
      },
    ]);
  }, [conventions, openCancelModal, openEndedModal]);

  return (
    <>
      {currentSelectedLineInfo.current && (
        <>
          <AlertModal
            title="Supprimer la convention"
            body={
              <div className="grid gap-4 py-4">
                <p>Êtes-vous sûr de vouloir supprimer la convention?</p>
                <p>Ceci entrainera la suppression de la convention ainsi que la clotûre du suivi de formation.</p>
              </div>
            }
            submitText="Supprimer"
            onSubmit={() => {
              closeCancelModal();
              if (currentSelectedLineInfo.current) {
                dispatch(cancelConvention(currentSelectedLineInfo.current.id));
              }
            }}
            isOpen={isCancelModalOpen}
            onClose={() => closeCancelModal()}
          />
          <AlertModal
            title="Terminer prématurément la convention"
            body={
              <div className="grid gap-4 py-4">
                <p>Êtes-vous sûr de vouloir terminer la convention?</p>
                <p>Ceci entrainera la fin de la convention. Le suivi de la formation sera toujours actif.</p>
              </div>
            }
            submitText="Terminer"
            onSubmit={() => {
              closeEndedModal();
              if (currentSelectedLineInfo.current) {
                dispatch(endConvention(currentSelectedLineInfo.current.id));
              }
            }}
            isOpen={isEndedModalOpen}
            onClose={() => closeEndedModal()}
          />
        </>
      )}
      <DataTable
        columns={dataColumns}
        data={conventions}
        sortField="creationDate"
        order="desc"
        searchPlaceHolder="Rechercher une convention"
        displayDataViewOptions
      />
    </>
  );
};
