import { Button } from "@components/ui/button";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@components/ui/form";
import { useForm } from "react-hook-form";
import { ReportAnIssueBody } from "@user-management-context/shared/application/ports/user-gateway";
import {
  IssueReportingFormInputs,
  issueReportingSchema,
  issueReportingdefaultValues,
  formatFormDataToBodyData,
} from "@user-management-context/primary/shared/issue-reporting/form-validation/issue-reporting";
import { yupResolver } from "@hookform/resolvers/yup";
import { RadioGroup, RadioGroupItem } from "@components/ui/radio-group";
import { ComboboxOptions } from "@shared-kernel/primary/shared/combobox/combobox";
import { ISSUE_TYPE, ISSUE_TYPE_LABEL } from "@user-management-context/write/domain/types/shared/issue-reporting";
import { useEffect } from "react";
import { FetchingState } from "@redux/app-state";
import PlainTextLexical from "@shared-kernel/primary/shared/text-editor/plain-text/plain-text-lexical";

interface Props {
  onSubmit: (body: ReportAnIssueBody) => void;
  processing: FetchingState;
}

export function IssueReportingForm({ onSubmit, processing }: Props) {
  const form = useForm<IssueReportingFormInputs>({
    resolver: yupResolver(issueReportingSchema),
    defaultValues: issueReportingdefaultValues,
  });
  const {
    control,
    formState: { isDirty, defaultValues, isSubmitSuccessful },
    reset,
  } = form;

  useEffect(() => {
    if (processing === "success") {
      reset(issueReportingdefaultValues);
    }
  }, [processing, reset]);

  const handleOnSubmit = async (formBody: IssueReportingFormInputs) => {
    const body = formatFormDataToBodyData(formBody);
    onSubmit(body);
  };

  const typeOptions: ComboboxOptions[] = Object.values(ISSUE_TYPE).map(type => ({ label: ISSUE_TYPE_LABEL[type], value: type }));

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(handleOnSubmit)} className="space-y-4">
        <FormField
          control={form.control}
          name="type"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Type de signalement</FormLabel>
              <FormControl>
                <RadioGroup onValueChange={value => field.onChange(value)} value={field.value} className="flex flex-col space-y-1">
                  {typeOptions.map(option => (
                    <FormItem className="flex items-center space-x-3 space-y-0" key={option.value}>
                      <FormControl>
                        <RadioGroupItem value={option.value} />
                      </FormControl>
                      <FormLabel className="font-normal">{option.label}</FormLabel>
                    </FormItem>
                  ))}
                </RadioGroup>
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={control}
          name={"description"}
          render={({ field }) => (
            <FormItem>
              <FormLabel>Description</FormLabel>
              <FormControl>
                <PlainTextLexical<IssueReportingFormInputs>
                  name={field.name}
                  control={control}
                  defaultValue={defaultValues?.[field.name]}
                  isSubmitSuccessful={isSubmitSuccessful}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <div className="mt-4 flex justify-end gap-4">
          <Button type="submit" disabled={!isDirty}>
            Envoyer
          </Button>
        </div>
      </form>
    </Form>
  );
}
