import { SyntheticEvent, useEffect, useRef, useState } from "react";

import { AppState } from "@redux/app-state";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { useFundingRequestUpdated } from "./use-funding-request-updated";
import { ColumnDef } from "@tanstack/react-table";
import {
  FUNDING_REQUEST_ERROR_REASONS,
  ReviewedFundingRequestVM,
  SubmittedToFunderFundingRequestVM,
} from "@academy-context/read/domain/types/admin/funding-request";
import { formatDateToLocale } from "../../../../utils/formatting";
import { retrieveReviewedFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/reviewed-funding-request-list-retrieval/retrieve-reviewed-funding-requests";
import { retrieveSubmittedToFunderFundingRequests } from "../../../read/application/use-cases/admin/funding-request-list/submitted-to-funder/retrieve-submitted-to-funder-funding-requests";
import { Card, CardContent } from "@shared-kernel/primary/shared/shadcn/ui/card";
import { DataTable } from "@shared-kernel/primary/shared/shadcn/ui/data-table";
import { Checkbox } from "@shared-kernel/primary/shared/shadcn/ui/checkbox";
import { Combobox } from "@shared-kernel/primary/shared/combobox/combobox";
import { Button } from "@shared-kernel/primary/shared/shadcn/ui/button";
import { deleteFundingRequest } from "@academy-context/write/application/use-cases/admin/funding-request-deletion/delete-funding-request";
import { updateFundingRequestError } from "@academy-context/write/application/use-cases/admin/funding-request-error-update/update-funding-request-error";
import { revertFundingRequestToReview } from "@academy-context/write/application/use-cases/admin/funding-request-reversion/revert-funding-request-to-review";
import { Nullable } from "@shared-kernel/core/types/nullable";
import { AlertModal } from "@shared-kernel/primary/shared/modal/alert-modal";
import { SendAfdasRefreshNotificationModal } from "@academy-context/primary/admin/funding-request-list/send-afdas-refresh-notification-modal";
import { submitFundingRequestToFunder } from "@academy-context/write/application/use-cases/admin/funding-request-submission-to-funder/submit-funding-request-to-funder";
import { ORGANIZATION_NAME } from "@shared-kernel/domain/organisation";
import { AFDAS_STUDENT_STATUS_LABELS, FUNDER, FUNDER_LABELS } from "@academy-context/shared/domain/types/enums/funders";

const errorReasonLabelMap: Record<FUNDING_REQUEST_ERROR_REASONS, string> = {
  [FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD]: "Carence",
  [FUNDING_REQUEST_ERROR_REASONS.PASSWORD]: "Mot de passe",
  [FUNDING_REQUEST_ERROR_REASONS.REFUSED_BY_FUNDER]: "Refus",
  [FUNDING_REQUEST_ERROR_REASONS.OTHER]: "Autre",
  [FUNDING_REQUEST_ERROR_REASONS.NO_ERROR]: "Pas de problème",
};

const errorReasonOptions = Object.values(FUNDING_REQUEST_ERROR_REASONS).map(e => ({ label: errorReasonLabelMap[e], value: e }));

const reviewedFundingRequestscolumns: ColumnDef<ReviewedFundingRequestVM | SubmittedToFunderFundingRequestVM>[] = [
  {
    accessorKey: "reviewDate",
    header: () => "Date de finalisation (par Brigitte)",
    cell: info => formatDateToLocale(info.row.original.reviewDate),
    meta: {
      title: "Date de finalisation (par Brigitte)",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "training.startDate",
    header: () => "Date début stage",
    cell: info => formatDateToLocale(info.row.original.training.startDate),
    meta: {
      title: "Date début stage",
      width: "90px",
    },
    enableSorting: true,
  },
  {
    accessorKey: "funder.type",
    header: () => "Financeur",
    cell: info => {
      const label =
        FUNDER_LABELS[info.row.original.funder.type] +
        (info.row.original.funder.type === FUNDER.AFDAS
          ? ` (${AFDAS_STUDENT_STATUS_LABELS[info.row.original.funder.payload.studentStatus]})`
          : "");
      return label;
    },
    meta: {
      title: "Financeur",
    },
    enableSorting: true,
  },
  {
    accessorKey: "student.name",
    header: () => "Elève",
    meta: {
      title: "Elève",
    },
    enableSorting: true,
  },
  {
    accessorKey: "organization",
    header: () => "Société",
    cell: info => {
      const value = info.row.original.organization;
      return ORGANIZATION_NAME[value].long;
    },
    meta: {
      title: "Société",
    },
    enableSorting: true,
  },
  {
    accessorKey: "submissionDate",
    header: () => "Date de mise en ligne",
    cell: info => {
      const value = info.row.original.submissionDate;
      return value ? formatDateToLocale(value) : "";
    },
    meta: {
      title: "Date de mise en ligne",
      width: "90px",
    },
    enableSorting: true,
  },
];

export const FundingRequestToSubmitList = () => {
  const dispatch = useAppDispatch();
  const { data: reviewed } = useAppSelector((state: AppState) => state.reviewedFundingRequestsRetrieval);
  const { data: submitted } = useAppSelector((state: AppState) => state.submittedToFunderFundingRequestsRetrieval);
  const [dataColumns, setDataColumns] = useState<ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM>[]>([]);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const currentFundingRequest = useRef<Nullable<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM>>(null);
  const [isReversionModalOpen, setIsReversionModalOpen] = useState<boolean>(false);
  const [isAfdasRefreshNotificationModalOpen, setIsAfdasRefreshNotificationModalOpen] = useState<boolean>(false);

  useEffect(() => {
    dispatch(retrieveReviewedFundingRequests());
    dispatch(retrieveSubmittedToFunderFundingRequests());
  }, [dispatch]);

  useEffect(() => {
    const statusColumn: ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM> = {
      accessorKey: "status",
      header: () => "Mise en ligne",
      meta: {
        title: "Mise en ligne",
        width: "90px",
      },
      enableSorting: true,
      cell: info => {
        const { row } = info;
        const fundingRequest = row.original;
        const isSubmittedToAFDAS = info.row.original.status === "submitted_to_funder";
        return (
          <Checkbox
            id={fundingRequest.id}
            defaultChecked={isSubmittedToAFDAS}
            onClick={(event: SyntheticEvent) => {
              // Prevents checkbox from being checked/unchecked
              event.preventDefault();
              dispatch(submitFundingRequestToFunder({ id: fundingRequest.id, hasBeenSubmitted: !isSubmittedToAFDAS }));
            }}
          />
        );
      },
    };
    const errorColumn: ColumnDef<SubmittedToFunderFundingRequestVM | ReviewedFundingRequestVM> = {
      accessorKey: "error",
      header: () => "Problème",
      meta: {
        title: "Problème",
        width: "280px",
      },
      cell: ({ row }) => {
        const {
          original: { id, error },
        } = row;
        const isFundingRequestDeletable =
          error &&
          [
            FUNDING_REQUEST_ERROR_REASONS.REFUSED_BY_FUNDER,
            FUNDING_REQUEST_ERROR_REASONS.WAITING_PERIOD,
            FUNDING_REQUEST_ERROR_REASONS.OTHER,
          ].includes(error);
        return (
          <div className="grid grid-cols-2 gap-2">
            <div className="flex items-center">
              <Combobox
                options={errorReasonOptions}
                value={error ?? ""}
                onChange={value => {
                  if (value === FUNDING_REQUEST_ERROR_REASONS.PASSWORD) {
                    setIsAfdasRefreshNotificationModalOpen(true);
                    currentFundingRequest.current = row.original;
                  } else
                    dispatch(
                      updateFundingRequestError({ id, error: value as FUNDING_REQUEST_ERROR_REASONS, sendNotificationToStudent: false })
                    );
                }}
                placeholder="Selectionner"
                search={{
                  notFoundText: "Pas d'erreur trouvée.",
                  commandInputPlaceHolder: "Chercher erreur...",
                }}
              />
            </div>
            <div className="flex flex-col gap-2">
              {isFundingRequestDeletable && (
                <>
                  <Button
                    variant="destructive"
                    onClick={() => {
                      currentFundingRequest.current = row.original;
                      setIsDeleteModalOpen(true);
                    }}
                  >
                    Supprimer
                  </Button>
                  <Button
                    onClick={() => {
                      currentFundingRequest.current = row.original;
                      setIsReversionModalOpen(true);
                    }}
                  >
                    Retour validation
                  </Button>
                </>
              )}
            </div>
          </div>
        );
      },
    };
    const [reviewDate, startDate, funder, student, organization, submissionDate] = reviewedFundingRequestscolumns;
    if (reviewDate && startDate && student && submissionDate && funder && organization) {
      setDataColumns([reviewDate, startDate, student, funder, organization, statusColumn, submissionDate, errorColumn]);
    }
  }, [dispatch, reviewed, submitted]);

  useFundingRequestUpdated();

  return (
    <>
      <AlertModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onSubmit={() => {
          setIsDeleteModalOpen(false);
          if (currentFundingRequest.current) dispatch(deleteFundingRequest(currentFundingRequest.current.id));
        }}
        title="Supprimer la demande de financement"
        body="Êtes-vous sûr de vouloir supprimer cette demande ?"
        submitText="Supprimer"
      />
      <AlertModal
        isOpen={isReversionModalOpen}
        onClose={() => setIsReversionModalOpen(false)}
        onSubmit={() => {
          setIsReversionModalOpen(false);
          if (currentFundingRequest.current) dispatch(revertFundingRequestToReview(currentFundingRequest.current.id));
        }}
        title="Retourner à l'étape de validation de la demande de financement"
        body="Êtes-vous sûr de vouloir retourner à l'étape de validation de cette demande ?"
        submitText="Valider"
      />
      <SendAfdasRefreshNotificationModal
        fundingRequestId={currentFundingRequest.current?.id ?? null}
        student={currentFundingRequest.current?.student.name ?? null}
        isOpen={isAfdasRefreshNotificationModalOpen}
        onClose={() => setIsAfdasRefreshNotificationModalOpen(false)}
      />

      <Card>
        <CardContent className="space-y-2">
          <DataTable
            columns={dataColumns}
            data={[...reviewed, ...submitted]}
            sortField="training_startDate"
            order="asc"
            searchPlaceHolder="Rechercher une demande de financement"
          />
        </CardContent>
      </Card>
    </>
  );
};
