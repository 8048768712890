import { addDays, addMonths, eachDayOfInterval, format, isSunday, startOfMonth } from "date-fns";
import { toZonedTime } from "date-fns-tz";
import { USER_TIMEZONE } from "@user-management-context/read/domain/types/user";
import { fr } from "date-fns/locale";
import { commonAdapters } from "src/common-adapters";

export const selectDateOptions = (now: Date, additionalMonths: number) => {
  const zonedDate = toZonedTime(now, USER_TIMEZONE);

  const dateOptions = [];
  for (let index = 0; index < 12; index++) {
    const start = addMonths(zonedDate, index);
    // - 1 because date-fns (and the native Date object in JavaScript) uses the local time zone by default,
    // which can push you into the next day (or previous day) depending on your offset and the exact hour specified.
    // In other words, your “2023-08-31T22:00:00.000Z” might look like August 31 in UTC, but in a time zone that is e.g. UTC+2,
    // that date/time is actually already September 1 locally.
    const endMonth = addMonths(zonedDate, index + additionalMonths);
    const startMonthLabel = formatToLabel(start);
    const endMonthLabel = formatToLabel(endMonth);
    dateOptions.push({
      label: `${startMonthLabel} - ${endMonthLabel}`,
      value: startOfMonth(start).toISOString(),
    });
  }

  return dateOptions;
};

function formatToLabel(date: Date): string {
  const label = format(date, "MMMM yyyy", { locale: fr });
  return label[0]!.toUpperCase() + label.substring(1);
}

export function computeDaysSpreadBetweenDates(startDate: Date, endDate: Date): Date[] {
  const { dateProvider } = commonAdapters;
  console.log("🚀 ~ computeDaysSpreadBetweenDates ~ dateProvider:", dateProvider);
  // Convert both dates to a consistent time zone
  let zonedStart = toZonedTime(startDate, USER_TIMEZONE);
  let zonedEnd = toZonedTime(endDate, USER_TIMEZONE);

  // If start date is Sunday, move it forward one day (to Monday)
  if (isSunday(zonedStart) || dateProvider!.isBankHoliday(zonedStart)) {
    zonedStart = addDays(zonedStart, 1);
  }

  // If end date is Sunday, move it backward one day (to Saturday)
  if (isSunday(zonedEnd) || dateProvider!.isBankHoliday(zonedStart)) {
    zonedEnd = addDays(zonedEnd, -1);
  }

  // Generate all days in the [zonedStart, zonedEnd] range (inclusive)
  const allDays = eachDayOfInterval({
    start: zonedStart,
    end: zonedEnd,
  });

  // Filter out Sundays (just in case)
  const nonSundayOrBankHolidayDays = allDays.filter(day => !isSunday(day) && !dateProvider!.isBankHoliday(day));

  if (nonSundayOrBankHolidayDays.length < 10) {
    throw new Error(
      `Not enough non-Sunday or non bank holiday days to pick 10 dates between ${zonedStart.toDateString()} and ${zonedEnd.toDateString()}.`
    );
  }

  // Evenly sample exactly 10 from the filtered array
  // Indices will be 0, step, 2*step, ... up to 9*step
  const result: Date[] = [];
  const step = (nonSundayOrBankHolidayDays.length - 1) / 9; // 9 intervals for 10 items

  for (let i = 0; i < 10; i++) {
    const index = Math.round(i * step);
    const date = nonSundayOrBankHolidayDays[index];
    if (date) result.push(date);
  }

  return result;
}
