import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { UNDO_COMMAND, REDO_COMMAND, CAN_UNDO_COMMAND, CAN_REDO_COMMAND } from "lexical";
import { useState, useEffect } from "react";
import { mergeRegister } from "@lexical/utils";

const LowPriority = 1;

export function HistoryToolbarPlugin() {
  const [editor] = useLexicalComposerContext();
  const [canUndo, setCanUndo] = useState(false);
  const [canRedo, setCanRedo] = useState(false);

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        CAN_UNDO_COMMAND,
        (payload: boolean) => {
          setCanUndo(payload);
          return false;
        },
        LowPriority
      ),
      editor.registerCommand(
        CAN_REDO_COMMAND,
        (payload: boolean) => {
          setCanRedo(payload);
          return false;
        },
        LowPriority
      )
    );
  }, [editor]);

  return (
    <div className="flex gap-1 border-r border-gray-200 px-2">
      <button
        onClick={() => editor.dispatchCommand(UNDO_COMMAND, undefined)}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Undo"
        type="button"
        disabled={!canUndo}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M3 7v6h6" />
          <path d="M21 17a9 9 0 00-9-9 9 9 0 00-6 2.3L3 13" />
        </svg>
      </button>
      <button
        onClick={() => editor.dispatchCommand(REDO_COMMAND, undefined)}
        className="flex size-7 items-center justify-center rounded p-1 text-gray-500 transition-all hover:bg-gray-100 hover:text-gray-900 focus:bg-gray-100 focus:text-gray-900 focus:outline-none active:bg-gray-200 disabled:cursor-not-allowed disabled:opacity-40 data-[active=true]:bg-gray-100 data-[active=true]:text-gray-900"
        aria-label="Redo"
        type="button"
        disabled={!canRedo}
      >
        <svg viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2">
          <path d="M21 7v6h-6" />
          <path d="M3 17a9 9 0 019-9 9 9 0 016 2.3l3 2.7" />
        </svg>
      </button>
    </div>
  );
}
