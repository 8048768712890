import { array, number, object, string, boolean } from "yup";
import { REQUIRED_FIELD } from "@shared-kernel/primary/forms/constants";
import { InternalTraining } from "@academy-context/read/domain/types/admin/internal-training";

export type InternalTrainingFormInputs = {
  title: string;
  tag: string;
  objective: string;
  description: string;
  team: string;
  questions: { text: string; order: number }[];
  price?: number;
  isAdminOnly: boolean;
};

export const internalTrainingdefaultValues = {
  title: "Nouvelle formation",
  tag: "",
  objective: "",
  description: "",
  team: "",
  price: undefined,
  isAdminOnly: false,
  questions: [
    {
      text: "",
      order: 1,
    },
    {
      text: "",
      order: 2,
    },
    {
      text: "",
      order: 3,
    },
  ],
};

export const internalTrainingSchema = object().shape({
  title: string().max(255).required(REQUIRED_FIELD),
  tag: string().max(255).required(REQUIRED_FIELD),
  objective: string().required(REQUIRED_FIELD),
  description: string().required(REQUIRED_FIELD),
  team: string().max(255).required(REQUIRED_FIELD),
  price: number().min(1).max(4000).required(REQUIRED_FIELD),
  isAdminOnly: boolean().required(REQUIRED_FIELD),
  questions: array()
    .min(3, "Requiert au moins une question")
    .of(
      object().shape({
        text: string().max(255).required(REQUIRED_FIELD),
        order: number().required(REQUIRED_FIELD),
      })
    ),
});

export const formatInternalTrainingDataToFormData = (training: InternalTraining): InternalTrainingFormInputs => {
  const { tag, title, objective, description, team, price, questions, isAdminOnly } = training;

  return {
    tag,
    title,
    objective,
    description,
    team,
    price,
    questions,
    isAdminOnly,
  };
};

export const formatFormDataToBodyData = (training: InternalTrainingFormInputs): Omit<InternalTraining, "id" | "creationDate"> => {
  const { tag, title, objective, description, team, price, questions, isAdminOnly } = training;

  return {
    tag,
    title,
    objective,
    description,
    team,
    price: price ?? 0,
    questions,
    isAdminOnly,
  };
};
