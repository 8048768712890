import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "@redux/hooks";
import { AppState } from "@redux/app-state";
import { TrainingFollowUpDetailComponent } from "@academy-context/primary/shared/training-follow-up/training-follow-up-detail.components";
import { teacherRetrieveTrainingFollowUp } from "@academy-context/read/application/use-cases/teacher/training-follow-up-retrieval/retrieve-training-follow-up";
import { ROLES } from "@user-management-context/shared/domain/types/enums/roles";

export const TeacherTrainingFollowUpDetailContainer = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams<{ id: string }>();
  const { data: trainingFollowUp } = useAppSelector((state: AppState) => state.teacherTrainingFollowUpRetrieval);

  useEffect(() => {
    if (id) dispatch(teacherRetrieveTrainingFollowUp(id));
  }, [dispatch, id]);

  return <TrainingFollowUpDetailComponent trainingFollowUp={trainingFollowUp} role={ROLES.TEACHER} />;
};
