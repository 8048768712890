import { useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { FormInputs } from "./form-validation/funding-request";
import { TrainingDaysForm } from "@academy-context/primary/shared/trainings/training-days.component";
import { computeHalfDaysCount } from "@academy-context/read/domain/types/shared/training-day";

interface Props {
  now: Date;
  initialStartDate: Date;
}

export const TrainingDaysManualConfigForm = ({ now, initialStartDate }: Props) => {
  const { watch, setValue } = useFormContext<FormInputs>();

  const trainingDays = watch("trainingDays");
  const blocks = trainingDays.map(({ block }) => block);

  useEffect(() => {
    const halfDaysCount = computeHalfDaysCount(blocks.map(block => ({ block })));
    setValue("trainingHalfDays", halfDaysCount);
    // We need to use blocks (instead of trainingDays) otherwise we do not detect
    // when we're transitionning from a half day to a full day
  }, [setValue, blocks]);

  useEffect(() => {
    const startDate = trainingDays[0]?.date;
    if (startDate) setValue("trainingDaysIntervalStartDate", startDate);
    const endDate = trainingDays[trainingDays.length - 1]?.date;
    if (endDate) setValue("trainingDaysIntervalEndDate", endDate);
  }, [setValue, trainingDays]);

  return <TrainingDaysForm initialStartDate={initialStartDate} now={now} formContext="funding_request" numberOfMonthsDisplayed={2} />;
};
